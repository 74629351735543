$avatars__fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$avatars__bgColor: #0f84c0 !default;
$avatars__color: #fff !default;

$avatars__light_bgColor: #fff !default;
$avatars__light_color: #333 !default;

$avatars__regular: (
  'size': 30px,
  'fontSize': 15px
) !default;

$avatars__sm: (
  'size': 24px,
  'fontSize': 11px
) !default;

$avatars__xsm: (
        'size': 18px,
        'fontSize': 8px
) !default;

$avatars__lg: (
        'size': 48px,
        'fontSize': 22px
) !default;


$avatars__xlg: (
        'size': 64px,
        'fontSize': 30px
) !default;


@mixin avatar__size($map) {
  width: map-get($map, 'size');
  height: map-get($map, 'size');
  line-height: map-get($map, 'size');

  font-size: map-get($map, 'fontSize');
}

.avatar {
  border-radius: 50%;
  text-align: center;
  display: inline-block;

  font-family: $avatars__fontFamily;

  background-color: $avatars__bgColor;
  color: $avatars__color;

  font-weight: 400;

  @include avatar__size($avatars__regular);


  &.sm {
    @include avatar__size($avatars__sm);
  }

  &.xsm {
    @include avatar__size($avatars__xsm);
  }

  &.lg {
    @include avatar__size($avatars__lg);
  }

  &.xlg {
    @include avatar__size($avatars__xlg);
  }


  &.light {
    background-color: $avatars__light_bgColor;
    color: $avatars__light_color;
  }

}