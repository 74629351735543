.app-form {

  select.select {
    outline: none;
    border: 1px solid $forms__borderColor;
    font-size: 1.3rem;
    line-height: 2.8rem;
    padding: 0 0.5rem;
    width: 100%;
    min-height: calc(2.8rem + 2px);

    &:disabled {
      border: 1px solid $forms__borderColor--disabled;
    }

    &:not(:disabled) {
      &:hover {
        border: 1px solid $forms__borderColor--hover;
      }

      &:focus {
        border: 1px solid $forms__borderColor--focus;
      }
    }
  }

  &.small {
    select.select {
      font-size: 1.2rem;
      line-height: 2.2rem;
      padding: 0 0.3rem;
      min-height: calc(2.2rem + 2px);
    }
  }

}