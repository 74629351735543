.app-form {

  .read-only-text {
    border: 1px solid $forms__borderColor--disabled !important;

    display: block;
    min-height: 2.8rem;
    line-height: 1.8em;
    padding: 0.3em;
  }

  input.text-input, textarea, .read-only-text {
    outline: none;
    border: 1px solid $forms__borderColor;
    font-size: 1.3rem;
    line-height: 2.8rem;
    padding: 0 0.5rem;
    width: 100%;
    min-height: 2.8rem;

    &:disabled, &:read-only {
      border: 1px solid $forms__borderColor--disabled !important;
    }

    &:not(:disabled), &:not(:read-only) {
      &:hover {
        border: 1px solid $forms__borderColor--hover;
      }

      &:focus {
        border: 1px solid $forms__borderColor--focus;
      }
    }

  }

  textarea {
    height: 94px;
    line-height: 2rem;
    padding: 0.5rem 0.5rem;

    &.short {
      height: 46px;
    }

    &.long {
      height: 184px;
    }
  }

  &.small {
    input.text-input, textarea {
      font-size: 1.2rem;
      line-height: 2.2rem;
      padding: 0 0.3rem;
      min-height: 2.2rem;
    }
  }

  &.underlined {

    input.text-input, textarea {
      border-color: transparent ;
      padding: 0;

      &:hover {
        border-bottom: 1px solid $forms__borderColor--hover;
      }

      &:focus {
        border: 1px solid $forms__borderColor--focus;
        padding: 0 0.2rem;
      }
    }

  }
}