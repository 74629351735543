
$grid__qs__borderColor: #dedede !default;
$forms__borderColor--disabled: #efefef !default;
$forms__borderColor--hover: #aaa !default;
$forms__borderColor--focus: #3f83b4 !default;

.grid .grid-toolbar .lock-columns-wrapper {

  .svg-inline--mdi {
    font-size: 24px;
    vertical-align: -7px;
    margin-right: 5px;
    color: #888;
  }

  .lock-input {
    outline: none;
    border: 1px solid #dedede;
    font-size: 1.3rem;
    line-height: 2.8rem;
    padding: 0 0 0 1rem;
    width: 60px;
    min-height: 2.8rem;

    &:hover {
      border: 1px solid $forms__borderColor--hover;
    }

    &:focus {
      border: 1px solid $forms__borderColor--focus;
    }

    &.active {
      background-color: #fffdda;
    }

  }

}