
//body font-family


//app-body dodane żeby napewno nadpisać CSS, niezależnie od kolejności styli
.app-body {
  .pika-single {
    font-family: $forms__fontFamily;
    border: 1px solid #d1d1d1;
  }

  .pika-single.is-bound {
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }

  .pika-label {
    font-size: 12px;
    line-height: 14px;
  }

  .pika-lendar {
    width: 210px;
    margin: 5px;
  }

  .pika-prev, .pika-next {
    height: 23px;
  }

  .pika-table {
    abbr {
      cursor: default;
      font-weight: 500;

      &[title] {
        text-decoration: none;
      }
    }
  }

  .is-disabled .pika-button, .is-inrange .pika-button {
    background: rgba(90, 90, 90, 0.1);
  }

  .is-disabled .pika-button {
    color: #888;
  }

  .is-today .pika-button {
    color: $forms__borderColor--focus;
    font-weight: 700;
  }

  .pika-button:hover, .pika-row.pick-whole-week:hover .pika-button {
    color: #666;
    background: rgba($forms__borderColor--focus, 0.2);
    box-shadow: none;
    border-radius: 0px;
  }

  .is-selected .pika-button, .has-event .pika-button {
    color: #fff;
    font-weight: bold;
    background: rgba($forms__borderColor--focus, 1);
    border-radius: 0px;
  }
}