
@use "sass:list";

$app__workspace_fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$app__workspaceHeader_borderBottom: 1px solid rgba(0, 0, 0, 0.1);
$app__workspaceTitle_color: #333;

$app__workspaceIcon_color: rgb(173, 173, 173);
$app__workspaceIcon_size: 24px;
$app__workspaceTitle_lineHeight: 30px;

$app__workspaceTitle_font: normal normal 400 list.slash(20px, $app__workspaceTitle_lineHeight) $app__workspace_fontFamily !default;

.app-workspace {

  .app-workspace-header {
    width: 100%;
    margin: 20px 20px 0 20px;
    border-bottom: $app__workspaceHeader_borderBottom;

    .title-row {
      align-content: flex-start;
      margin-bottom: 10px;

      .icon-col {
        .icon-wrapper {
          margin-right: 10px;
          border-right: 1px solid rgba(0, 0, 0, 0.1);

          padding: ($app__workspaceTitle_lineHeight - $app__workspaceIcon_size)*0.5 10px ($app__workspaceTitle_lineHeight - $app__workspaceIcon_size)*0.5 0;

          .icon {
            color: $app__workspaceIcon_color;
            width: $app__workspaceIcon_size;
            height: $app__workspaceIcon_size;
          }
        }

      }

      .title-col {

        .title {
          font: $app__workspaceTitle_font;
          color: $app__workspaceTitle_color;


          a.dropdown {
            color: $app__workspaceTitle_color;
            border-bottom: 1px dashed #d2d2d2;
            border-right: 1px solid transparent;
            border-left: 1px solid transparent;
            border-top: 1px solid transparent;
            padding: 2px 8px 3px 6px;
            transition: all 0.1s ease-in-out;

            &:after {
              content: '';
              width: 8px;
              height: 8px;
              display: inline-block;
              margin: 0 0.1em 0 0.2em;
              border-bottom: 1px solid $app__workspaceTitle_color;
              border-right:1px solid $app__workspaceTitle_color;

              transform: rotate(45deg);
              opacity: 0.5;
              vertical-align: 6px;
              transition: all 0.1s ease-in-out;
            }


            &:hover {
              border: 1px solid #d2d2d2;
              text-decoration: none;
            }
          }


        }

        .subtitle {
          margin-top: 6px;
          font-size: 11px;
          color: $app__workspaceTitle_color;
        }

      }

    }

  }

  .nav-row {
    margin-top: 5px;
  }

  .workspace-content {

  }

}