@mixin buttons__style($map, $outlineMap, $dropColor) {

  color: map-get($map, 'color');
  border: map-get($map, 'border');
  background-color: map-get($map, 'bgColor');

  &:hover:not(.disabled) {
    color: map-get($map, 'color--hover');
    border: map-get($map, 'border--hover');
    background-color: map-get($map, 'bgColor--hover');

    &.dropdown:after {
      opacity: 1;
    }
  }

  &.disabled {
    &:hover {
      cursor: default;
    }

    opacity: 0.4;

    &.dropdown:after {
      opacity: 0.5;
    }
  }

  &.outline {
    color: map-get($outlineMap, 'color');
    border: map-get($outlineMap, 'border');
    background-color: transparent;

    &:hover:not(.disabled) {
      color: map-get($outlineMap, 'color--hover');
      border: map-get($outlineMap, 'border--hover');
      background-color: transparent;

      &.dropdown:after {
        border-bottom: 1px solid map-get($outlineMap, 'color--hover');
        border-right:1px solid  map-get($outlineMap, 'color--hover');
      }
    }

    &.dropdown-fill:after {
      border-top: 5px solid map-get($outlineMap, 'color');
    }

    &.dropdown:after {
      border-bottom: 1px solid map-get($outlineMap, 'color');
      border-right:1px solid map-get($outlineMap, 'color');
    }
  }

  &.dropdown-fill:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $dropColor;
    vertical-align: 1px;
    margin: 0 0.1em 0 0.5em;
    display: inline-block;
    margin-right: -0.5rem;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
  }

  &.dropdown:after {
    content: '';
    width: 6px;
    height: 6px;
    display: inline-block;
    margin: 0 0.1em 0 0.7em;
    border-bottom: 1px solid $dropColor;
    border-right:1px solid $dropColor;

    transform: rotate(45deg);
    opacity: 0.5;
    vertical-align: 3px;
    transition: all 0.3s ease-in-out;
  }

}

@mixin buttons__size($map) {

  font: map-get($map, 'font');
  height: map-get($map, 'height');
  padding: map-get($map, 'padding');

  .svg-inline--mdi {
    font-size: map-get($map, 'iconSize');
    vertical-align: map-get($map, 'iconVerticalAlign');
    margin: map-get($map, 'iconMargin');

  }

}