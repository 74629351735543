.add-comment-form {

  textarea {
    height: 64px;
  }

}

.comment-row {
  margin-top: 10px;

  .comment {
    margin-left: 10px;
    padding: 10px;
    font-size: 1.3rem;
    border: 1px solid #eee;

    .comment-meta {
      color: #777;
      padding-bottom: 10px;

      b {
        font-weight: 500;
      }
    }

    .comment-text {
      color: #333;
    }
  }
}