$loader__color:  #1194d8;
$loader__overlayColor: rgba(255,255,255,0.7) !default;

@keyframes loader-spin {
  100% {
    transform:rotate(360deg);
  }
}

.loader {
  display: none;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: $loader__overlayColor;
  text-align: center;
  padding-top: 50px;
  z-index: 399;

  .loader-svg {
    height: 80px;
    width: 80px;
    position: sticky;
    top: 100px;

    .loader-svg-stroke {
      stroke: $loader__color;
      stroke-dashoffset: 40;
      fill: transparent;
    }

    circle {
      stroke-width: 10px;
      stroke-linecap: butt;
      stroke-dasharray: 240;
      animation:loader-spin 1.2s linear infinite;
    }

  }

  &.small {
    .loader-svg {
      width: 50px;
      height: 50px;
    }
  }
}

.loading > .loader {
  display: block;
}