$app__notifications_width: 600px;

.app .app-topbar nav a.notifications-topbar-button {
  position: relative;
  padding-right: 18px;

  .counter {
    min-width: 20px;
    padding-left: 2px;
    padding-right: 2px;
    height: 20px;
    font-size: 11px;
    font-weight: 700;
    color: #fff;
    background-color: #c91212;
    display: inline-block;
    line-height: 20px;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    top: 6px;
    right: 5px;
  }
}

.app-notifications {
  overflow: auto;
  position: fixed;
  top: $app__topbar_height;
  bottom: 0;
  right: -1 * $app__notifications_width;
  width: $app__notifications_width;

  background-color: rgba(255, 255, 255, 1);

  transition: all 0.2s ease-in-out;
  border-left: 1px solid transparent;
  box-shadow: 2px 2px 5px 5px transparent;
  z-index: 499;

  &.visible {

    border-left: 1px solid #ddd;
    box-shadow: 2px 2px 16px 5px rgb(0 0 0 / 10%);
    right: 0;
  }


  .user-noti {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #ddd;

    .user-noti__left {
      flex-grow: 0;
      flex-basis: 30px;
    }

    .mark-read {
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 2px solid #0f84c0;
      opacity: 0.6;
      border-radius: 50%;

      &:hover {
        opacity: 1;
        background-color: #0f84c0;
        text-decoration: none;
      }
    }

    .user-noti__right {
      flex-grow: 1;
    }

    .user-noti__type {
      font-size: 13px;
      color: #0b5c87;
      display: inline-block;
      margin-top: 1px;
      font-weight: 500;
    }

    .user-noti__empl {
      margin-top: 10px;
      margin-bottom: 8px;
      .user-noti__empllink {
        color: #000;
        font-size: 13px;
        font-weight: 600;
      }
    }

    .user-noti__valuechange {
      font-size: 13px;
      font-weight: 400;
      margin: 10px 0;
      .svg-inline--mdi {
        color: #999;
      }

      .user-noti__old {
        padding: 3px 2px;
        background-color: #ededed;
        border: 1px solid #dbdbdb;
        color: #878787;
        display: inline-block;
        margin-right: 0px;
      }

      .user-noti__new {
        padding: 3px 2px;
        background-color: #e9f1fb;
        border: 1px solid #85b7ce;
        color: #000;
        margin-left: 0px;
        display: inline-block;
      }
    }

    .user-noti__openlink {
      font-size: 13px;
    }

    .user-noti__company {
      float: right;
      padding: 3px 6px;
      background-color: #50769b;
      color: #fff;
      border-radius: 3px;
    }

    .user-noti__text {
      font-size: 13px;
      font-weight: 400;
      color: #333;
      text-decoration: none;
      padding-bottom: 5px;
      margin-bottom: 5px;
      margin-top: 8px;
    }

    .user-noti__detail {
      display: flex;
      font-size: 13px;
      margin-top: 5px;
      .user-noti__detaillbl {
        color: #999;
        flex-grow: 0;
        width: 30%;
      }

      .user-noti__detailval {
        color: #333;
        flex-grow: 1;
      }
    }

  }

  .empty {
    text-align: center;
    padding: 30px;
    font-size: 16px;
    font-weight: 500;
    color: #999;
  }


}