/*
$forms__borderColor: #dedede !default;
$forms__borderColor--disabled: #efefef !default;
$forms__borderColor--hover: #aaa !default;
$forms__borderColor--focus: #3f83b4 !default;
 */

.select2-container {
  z-index: 10;
}

.select2-container.select2-container--default.select2-container--open {
  z-index: 9999;
}


select + .select2-container {
  width: 100% !important;
}

.error {
  .select2-container--default {

    .select2-selection--single {
      border: 1px solid $forms__errorColor;
    }
  }
}

.select2-container--default {

  .select2-selection--single {
    border-radius: 0;
    border: 1px solid $forms__borderColor;
    height: 30px;
    font-size: 1.3rem;
    line-height: 2.8rem;

    &:hover {
      border: 1px solid $forms__borderColor--hover;
    }

    &:focus {
      outline: 0;
    }

    .select2-selection__arrow b {
      border-color: #000 transparent transparent transparent;
    }

  }

  &.select2-container--open {
    .select2-selection--single {
      border-color: $forms__borderColor--focus;

      .select2-selection__arrow b {
        border-color: transparent transparent #000 transparent;
      }
    }

    &.select2-container--below {
      .select2-selection--single {
        border-bottom-color: #fff;
      }
    }
  }


  .select2-search--dropdown {
    padding:0;

    .select2-search__field {
      line-height: 28px;
      font-size: 13px;
      padding: 0 4px 0 6px;
      outline: 0;
      border: 0;
      border-bottom: 1px solid #ddd;
    }
  }

  .select2-dropdown {
    border-radius: 0;
    border: 1px solid $forms__borderColor--focus;

    &.select2-dropdown--below {
      border-top: none;
    }
  }

  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $forms__borderColor--focus;
  }

  .select2-results__option {
    font-size: 1.3rem;
    small {
      font-size: 1.1rem;
      line-height: 1.5rem;
      margin-top: 4px;
      display: inline-block;
    }
  }
}

