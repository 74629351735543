$app__topbar_fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$app__topbar_height: 50px !default;
$app__topbar_bgColor: #fff !default;

$app__topbar_fontSize: 1.3rem;


.app .app-topbar {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  z-index: 500;
  display: flex;
  align-items: center;
  align-content: center;

  background-color: #fff;

  font-size: $app__topbar_fontSize;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);

  height: $app__topbar_height;
  font-family: $app__topbar_fontFamily;

  & + * {
    margin-top: $app__topbar_height
  }

  .app-topbar-left {
    z-index: 100;
    width: $app__leftnav_width;
    flex-grow: 0;
    flex-shrink: 0;

    img {
      line-height: $app__topbar_height;
      vertical-align: middle;
      margin-left: 10px;
      max-height: 20px;
    }

    .svg-inline--mdi {
      color: #000;
      margin-left: 20px;
      height: 24px;
      width: 24px;
      vertical-align: middle;
    }
  }

  .app-topbar-content {
    align-self: stretch;
    display: flex;
    flex-grow: 1;
    //background-color: #1aa107;
  }

  .app-topbar-right {
    flex-grow: 0;
    flex-shrink: 0;
    //background-color: #E0F2BE;
  }



}

@import "partials/user";
@import "partials/nav";