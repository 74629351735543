@media print {
  .app-modulesbar, .app-left-nav {
    display: none;
  }

  .app-topbar {
    display: none !important;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  #main {
    margin-left: 0 !important;
    width: 100% !important;
    margin-top: 0 !important;
  }

  .app-workspace-header {
    height: 0!important;
    overflow: hidden!important;
    border: 0!important;
    margin: 0 !important;
  }
}

.olap-report {

  .olap-report--header {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: .5em;
  }

  data {
    white-space: nowrap;
  }

  .table, td, th {
    font-size: 13px;
    border: 1px solid #ccc;
  }

  .table{
    border: 1px solid #ccc;

    thead {
      position: sticky;
      top:0;

      th {
        background-color: #fff;
      }
      tr {
        border-top: 1px solid #ccc;
      }
    }

    td, th {
      font-size: 13px;
      border: 1px solid #ccc;
      padding: 0.6em;
    }

    tr.even-row {
      background-color: #f6f6f6;
    }

    .value-cell, .sum-value-cell {
      text-align: right;
    }

    .sum-time-cell {
      background-color: #dbe7f3;
    }


    th {
      font-weight: 500;

      &.time-name-head {
        text-align: center;
        background-color: #f2f7fc;
      }

      &.time-head {
        background-color: #e6f1fc;
      }

      &.sum-time-head {
        background-color: #dbe7f3;
      }

    }




    .sum-label-cell-level-0 {
      background-color: #c8e1bd;
    }

    .sum-value-cell-level-0 {
      background-color: rgba(200, 225, 189, 0.46);
    }

    .sum-time-cell-level-0.sum-value-cell-level-0 {
      background-color: #b2dcc1;
    }


    .sum-label-cell-level-1 {
      background-color: #d1e3c9;
    }

    .sum-value-cell-level-1 {
      background-color: rgba(209, 227, 201, 0.46);
    }

    .sum-time-cell-level-1.sum-value-cell-level-1 {
      background-color: #c0decb;
    }


    .sum-label-cell-level-2 {
      background-color: #e5eee1;
    }

    .sum-value-cell-level-2 {
      background-color: rgba(229, 238, 225, 0.49);
    }


    .sum-time-cell-level-2.sum-value-cell-level-2 {
      background-color: #d4e8db;
    }


  }

}