$appform_borderColor: #dedede !default;
$appform_borderHoverColor: #aaa !default;
$appform_borderFocusColor: #3f83b4 !default;
$appform_borderErrorColor: #b12a28 !default;


.grid-filter {
    float: left;

    border-right: 1px solid transparent;

    width:0;
    transition: all 0.5s ease-in-out;

    //box-shadow: inset -5px 0px 8px -8px rgba(0, 0, 0, 0.3);

    background-color: #fefefe;

    position: absolute;
    top: 0;
    bottom: 0;

    &.toggle-off{
        .toggle {
            display: none;
        }
    }

    .grid-filter-container {
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease-in-out;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
    }

    .grid-filter-header {
        border-bottom: 1px solid #eee;

        position: absolute;
        height: 50px;
        top: 0;
        left: 0;
        right:0;

        display: flex;
        align-items: center;
        align-content: space-between;
        padding: 0 15px 0 0;

        min-width: 320px;
        overflow: hidden;
        .title {
            font-weight: 500;
            color: #333;
            font-size: 13px;
            flex-grow:1;
        }

        .grid-filter-submit .button{
            margin-left: 10px;
        }

    }

    /*
    .grid-filter-submit {
        border-bottom: 1px solid #eee;
        background-color: #fff;
        box-sizing: border-box;


        padding: 6px 16px;
        text-align: right;

        -webkit-box-shadow: inset -5px 0px 8px -8px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: inset -5px 0px 8px -8px rgba(0, 0, 0, 0.3);
        box-shadow: inset -5px 0px 8px -8px rgba(0, 0, 0, 0.3);

        .button {
            margin-left: 10px;
        }
    }
    */


    .grid-filter-fields {
        position: absolute;
        top: 51px;
        left: 0;
        right:0;
        bottom:0;
        overflow: hidden auto;


        .filter-row {
            padding: 10px 15px 10px 0;
            border-bottom: 1px solid #ededed;
            min-width: 300px;
            width: 100%;

            &.active {
                background-color: #ffffea;
            }

            .select2-container--default .select2-selection--multiple {
                border: 1px solid $appform_borderColor;
                &:hover {
                    border: 1px solid $appform_borderHoverColor;
                }
            }

            .select2-container--default.select2-container--focus .select2-selection--multiple{
                border: 1px solid $appform_borderFocusColor;
            }

            .select2-container--default .select2-selection--multiple {
                border-radius: 0;
            }
            .select2-container .select2-selection--multiple {
                min-height: 30px;
            }

            .select2-container--default .select2-selection--multiple .select2-selection__choice {
                line-height: 22px;
                margin-top: 2px;
                border-radius: 0;
                background-color: #f3f3f3;
                border: 1px solid #bfbfbf;
                font-size: 12px;
            }

            .select2-container--default .select2-selection--single {
                border-radius: 0px;
                height: 23px;
                border: 1px solid $appform_borderColor;
                &:hover {
                    border: 1px solid $appform_borderHoverColor;
                }
            }

            .select2-container--default.select2-container--open .select2-selection--single {
                border: 1px solid $appform_borderFocusColor;
            }

            .select2-container--default .select2-selection--single .select2-selection__arrow {
                height: 21px;
            }




            label {
                font-size: 13px;
                font-weight: 400;
                display: block;
                margin-bottom: 5px;
                color: #333;
            }

            input[type=text]{
                outline: none;
                padding: 4px;
                font-size: 12px;
                border: 1px solid $appform_borderColor;
                width: 100%;

                &:hover {
                    border: 1px solid $appform_borderHoverColor;
                }

                &:focus {
                    border: 1px solid $appform_borderFocusColor;
                }

                &.error {
                    border: 1px solid $appform_borderErrorColor;
                }

            }

            select{
                outline: none;
                padding: 1px 1px 2px 1px;
                font-size: 12px;
                border: 1px solid $appform_borderColor;
                width: 100%;

                &:hover {
                    border: 1px solid $appform_borderHoverColor;
                }

                &:focus {
                    border: 1px solid $appform_borderFocusColor;
                }
            }


            &.bigdecimal-range, &.integer-range {
                input {
                    width: 40%;
                }
            }

            &.date-range {
                input {
                    width: 80px;
                }
                input.number {
                    width: 60px;
                }
                select {
                    width: 70px;
                }

            }

        }
    }


    .toggle {

        display: none;
        width:32px;

        position: absolute;
        top: 4px;
        right: -1px;

        background-color: #fff;
        height: 30px;
        //width: 15px;

        border: 1px solid #ccc;
        border-right: 0;

        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        cursor: pointer;

        background-size: 10px 10px;
        //background-image: url('../images/right-chevron.svg');
        //background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 238.003 238.003' style='enable-background:new 0 0 238.003 238.003;' xml:space='preserve'%3E%3Cpath d='M181.776,107.719L78.705,4.648c-6.198-6.198-16.273-6.198-22.47,0s-6.198,16.273,0,22.47 l91.883,91.883l-91.883,91.883c-6.198,6.198-6.198,16.273,0,22.47s16.273,6.198,22.47,0l103.071-103.039 c3.146-3.146,4.672-7.246,4.64-11.283C186.416,114.902,184.89,110.833,181.776,107.719z' fill='%238c8c8c'/%3E%3C/svg%3E");

        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAFZJREFUOI3N0TEOgCAMQNGPu4k4cyTRK5sgeiojAxsbLRC7Nu93KHQao8EOuACriWxAbBG5gfUXkadVZC4XU0XkBT7JdU9+6yLBuwYfWhyk2AGnFI+fBHADDhRY1ugdAAAAAElFTkSuQmCC');

        //background-size: 65% 45%;
        background-repeat: no-repeat;
        background-position: center right 0px;

        z-index: 10;

        svg {
            width: 16px;
            height: 16px;
            margin: 6px 3px;
            color: #666;
        }
    }

    &.active {
        visibility: visible;
        opacity: 1;
        width: 320px;
        border-right: 1px solid #ccc;

        .grid-filter-container {
            visibility: visible;
            opacity: 1;
        }

        + .grid-inner-container {
            margin-left: 320px;
        }

        .toggle {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAFdJREFUOI1jYKAjYKREszADA8MxBgYGCXI1H2dgYPAgR7MIAwPDCQYGBvcB0+xGjmZ+UjQzYRH7D8Vs5NgOA0IMkJD3GRSGHBsUhggyMDAcYiAzKdMWAACJPw4dxvbhoAAAAABJRU5ErkJggg==');
        }
    }


    + .grid-inner-container {
        transition: margin-left 0.5s ease-in-out;
    }

}

