.grid .grid-cards-container {
  padding: 0 5px 5px 5px;

  &::after {
    content: "";
    clear: both;
    display: table;
  }

  .grid-card {
    position: relative;
    border: 1px solid #eee;
    width: calc(25% - 10px);
    float: left;
    margin: 5px;
    cursor: pointer;

    &:hover {
      border: 1px solid #9ec8e4;
      outline: 1px solid #9ec8e4;
    }

    &::after {
      content: "";
      clear: both;
      display: table;
    }

    .id-select {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .img-thumb {
      width: 100%;
      height: 180px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .no-img-thumb {
      height: 180px;
      width: 100%;
      background-color: #f6f6f6;

      .icon {
        text-align: center;
        padding-top: 10px;
         svg {
           width: 36px;
           height: 36px;
           color: #868686;
         }
      }

      .type {
        margin: 10px auto 0 auto;
        padding: 3px;
        background-color: #9c9c9c;
        color: #fff;
        border-radius: 2px;
        font-size: 9px;
        font-weight: 600;
        display: block;
        width: 48px;
        text-align: center;

      }

      .name {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        white-space: nowrap;
        font-size: 12px;
        background-color: #fff;
        overflow: hidden;
        border: 10px solid #fff;
      }

    }

  }

  .grid-card[data-width='20'] {
    width: calc(20% - 10px);
    min-width: 120px;
  }

  .grid-card[data-width='10'] {
    width: calc(10% - 10px);
    min-width: 120px;
    .img-thumb,.no-img-thumb {
      height: 120px;
    }
  }

}