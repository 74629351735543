/*
Generowanie ustaiwen wierszy z paddingiem
 */
@mixin padd-mixin($width, $prefix, $suffix:"") {

  .#{$prefix}padd#{$suffix} {
    //overflow: hidden;

    &.grid-padd {
      padding: 2 * $width;

      > .row > *[class^="col-"] > .cell,
      > .row > *.col > .cell {
        margin-bottom: 2 * $width !important;
      }
    }

    > .row {
      margin-left: -$width;
      margin-right: -$width;

      > .cell[class^="col-"],
      > .cell.col {
        padding: 0 $width;
        position: relative;
      }

      > *[class^="col-"],
      > *.col  {
        padding: 0 $width;
        position: relative;
      }

      .cell-inline {
        padding: 0 $width;
        position: relative;
      }

    }

    &.with-margins {
      margin: 2*$width 2*$width 0 2*$width;
    }
  }

}


/*
  ustawienia dodatkowe kolumny dla responsywnych
 */
@mixin settings($prefix) {

  //text-alig settings

  .#{$prefix}text-right {
    text-align: right;
  }

  .#{$prefix}text-center {
    text-align: center;
  }

  .#{$prefix}text-left {
    text-align: left;
  }

  //flex settings

  .#{$prefix}align-right {
    justify-content: flex-end;
  }

  .#{$prefix}align-justify {
    justify-content: space-between;
  }

  .#{$prefix}align-center {
    justify-content: center;
  }

  //flex order

  .#{$prefix}order-first {
    order: -1;
  }

  .#{$prefix}order-last {
    order: 999999999;
  }
}


/*
Generowanie ustaiwen wierszy z paddingiem
 */
@mixin rows-padd-mixin($width, $prefix, $suffix:"") {

  .#{$prefix}rows-padd#{$suffix} {
    //overflow-x: hidden;

    .row + .row {
      padding-top: 2*$width;
    }
  }

}

/*
Responsywna kolumna
 */
@mixin col-mixin($i, $width, $prefix, $suffix:"") {

  .#{$prefix}col-#{$i}#{$suffix} {
    max-width: $width;
    flex-basis: $width;
    flex-grow: 0;
    flex-shrink: 0;

    &.strech {
      display: flex;
    }
  }

  .#{$prefix}collapse-left-#{$i}#{$suffix} {
    width: $width;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    left: -$width;
    z-index: 100;
    transition: all 0.5s ease-in-out;
    flex-basis: 0;

    &.is-visible {
      left: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      flex-basis: $width;
    }

    &.is-inlined {
      position: relative;
      left: 0;
      margin-right: 0;
    }
  }

  .#{$prefix}collapse-right-#{$i}#{$suffix} {
    width: $width;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    right: -$width;
    margin-left: -$width;
    transition: all 0.5s ease-in-out;

    &.is-visible {
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 100;
      position: absolute;
      flex-basis: $width;
    }

    &.is-inlined {
      position: relative;
      margin-left: 0;
      right: 0;
    }
  }
}