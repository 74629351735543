.grid .grid-settings.dropdown-form {

  .grid-settings-columns {
    padding: 10px;
    max-height: 192px;
    min-width: 400px;
    overflow: auto;

    label {
      font-size: 1.3rem;
      display: block;
      margin-bottom: 0.5rem;

      input {
        vertical-align: -2px;
        margin-right: 5px;
      }
    }

    border-bottom: 1px solid #ddd;
  }

  .grid-settings-page-size {
    padding: 10px;
    font-size: 1.3rem;
    select {
      margin-left: 10px;
      font-size: 1.3rem;
      padding: 0.2rem 0.5rem;
    }
  }

  .grid-setting-submit-row {
    border-top: 1px solid #ddd;
    padding: 10px;
    text-align: right;
    .button {
      margin-left: 10px;
    }
  }



}