$app__fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

////$applayout_small_fontFamily: 'Montserrat', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", arial, sans-serif !default;
$applayout_small_fontFamily: 'Nunito Sans', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", arial, sans-serif !default;

@import "leftnav/leftnav";
@import "topbar/topbar";
@import "workspace/_workspace";

.app {
  width: 100vw;

  .main {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  .app-right-content {
    position: relative;
    height: 100%;

    .app-workspace {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;

      & > .column {
        flex-basis: 1px;
        height: 100%;
      }

      .workspace-content {
        overflow: auto;
      }
    }
  }

}

$modwidth: 58px;

.app-body-with-module-bar {

  .app .app-topbar .app-topbar-right {
    margin-left:auto;;
    &+.app-topbar-right {
      margin-left: unset;
    }
  }

  .app-topbar-left-modulename {
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
  }

  .app-modulesbar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: $modwidth;
    background-color: #0c6695;
    box-shadow: inset -3px 0 7px 0 rgb(0 0 0 / 40%);




    .app-modulesbar-top nav, .app-modulesbar-bottom nav{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 10px;

      a {
        font-size: 24px;
        color: rgba(255,255,255,0.5);
        display: block;
        padding: 4px 6px 6px 6px;
        margin-bottom: 10px;
        border-radius: 2px;

        &.active {
          color: rgba(255,255,255,1);
          background-color: rgba(255,255,255,0.2);
        }

      }


    }



  }

  .app .app-topbar {
    left: $modwidth;
    width: calc(100% - #{$modwidth});
  }

  .app .main {
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc(100% - #{$modwidth});
    margin-left: $modwidth;
  }
}