.file-browser {

  .prev-container {
    padding: 10px;
  }

  .files-list {

    a {
      display: block;
      padding: 8px 10px;
      color: #333;
      font-size: 13px;
      text-decoration: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: #fafafa;
      }

      &.selected {
        background-color: #efefef;
        color: #000;
      }


      .svg-inline--mdi {
        font-size: 16px;
        color: #666;
      }
    }

  }

}