$grid__height: 42px;
$grid__height: 42px;

.grid {


  .grid-header-container {
    height: $grid__height;
    z-index: 1;
    position: relative;
    background-color: #fafafa;

    &::after {
      content: '';
      top: 1px;
      bottom: 0;
      right: 0;
      width: 17px;
      background-color: rgba(255, 255, 255, 1);
      position: absolute;
    }

    a, span {

      display: block;
      line-height: 40px;

      color: #000;
      text-decoration: none;

      font-weight: 400;
      text-align: left;
      padding: 0 5px;
      font-size: 1.3rem;

      &.reorder-clone {
        border: 1px solid #ddd;
        border-width: 1px 1px 0 1px;
        opacity: 0.8;
        overflow: hidden;
        white-space: nowrap;
      }

      small {
        display: block;
        font-weight: normal;
        line-height: 10px;
      }


    }

    a:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    table.locked-header-table {
      width: 1px;
      background-color: #f0f0f0;
      position: absolute;
      z-index: 2;
      box-shadow: 2px 0px 0px 0px rgba(12, 116, 168, 0.85), inset 0px -1px 0px 0px rgba(0,0,0,0.1);
    }

    .grid-header-wrapper table, table.locked-header-table {
      height: 40px;

      th {
        border: 1px solid transparent;
        border-right: 0px;
        border-left: 0px;
        white-space: nowrap;
        padding: 0;
        overflow: hidden;
        position: relative;

        font-weight: normal;

        &:first-child {
          border-left: 0;
        }

      }
    }


    .grid-header-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;

      //background-color: #f4f4f4;

      border-bottom: 1px solid #ddd;

      .resize-handle {
        background-color: rgba(99, 99, 99, 0.0);
        width: 5px;
        height: 40px;
        top: 0;
        position: absolute;
        cursor: e-resize;

        &.disabled {
          cursor: default;
        }

        &:not(.disabled) {
          &:hover::after, &.handler-drag::after {
            content: '';
            width: 1px;
            border-right: 1px solid #999;
            margin-left: 2px;
            height: 100%;
            display: inline-block;
          }
        }


        &.handler-drag {
          background-color: rgba(99, 99, 99, 0.2);
        }

        &.reorder-highlight {
          background-color: rgba(102, 184, 244, 0.8);
        }
      }
    }

  }

}

@import "grid_header_with_groups";

