/*!package grid-system */

@use "sass:math";

$grid-system__basePadding: 5px !default;

@import 'mixins';
* {
  box-sizing: border-box;
}

.row {
  display: flex;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;

  &.v-stretch {
    align-items: stretch;
  }

  &.v-middle {
    align-items: center;
  }

  &.v-top {
    align-items: flex-start;
  }

  &.v-bottom {
    align-items: flex-end;
  }

}

@include padd-mixin($grid-system__basePadding, '', '');
@include padd-mixin(1.5 * $grid-system__basePadding, 'mid-', '');
@include padd-mixin(2 * $grid-system__basePadding, 'large-', '');
@include padd-mixin(3 * $grid-system__basePadding, 'xlarge-', '');
@include padd-mixin(4 * $grid-system__basePadding, 'xxlarge-', '');
@include padd-mixin(5 * $grid-system__basePadding, 'x5large-', '');

@include rows-padd-mixin($grid-system__basePadding, '', '');
@include rows-padd-mixin(1.5 * $grid-system__basePadding, 'mid-', '');
@include rows-padd-mixin(2 * $grid-system__basePadding, 'large-', '');
@include rows-padd-mixin(3 * $grid-system__basePadding, 'xlarge-', '');
@include rows-padd-mixin(4 * $grid-system__basePadding, 'xxlarge-', '');
@include rows-padd-mixin(5 * $grid-system__basePadding, 'x5large-', '');



.row {
  .col {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;

    &.nogrow {
      flex-grow: 0;
    }

  }

  @for $i from 1 through 12 {
    $width: percentage(math.div(1, 12)) * $i;
    @include col-mixin($i, $width, '');
  }

  @for $i from 1 through 20 {
    $width: percentage(5*0.01) * $i;
    @include col-mixin($i*5, $width, '', '-proc');
  }

  @include settings("")

}

.inline-block {
  display: inline-block;
}

.block {
  display: inline-block;
}

@media screen and (max-width: 9000px) {
  .xsm-block, .sm-block, .md-block, .lg-block {
    display: none !important;
  }

  .xsm-inline-block, .sm-inline-block, .md-inline-block, .lg-inline-block {
    display: none !important;
  }
}

@media screen and (max-width: 1199.98px) {

  .lg-block {
    display: block !important;
  }

  .lg-inline-block {
    display: inline-block !important;
  }

  .lg-hide {
    display: none !important;
  }

  .row {
    @for $i from 1 through 12 {
      $width: percentage(math.div(1, 12)) * $i;
      @include col-mixin($i, $width, 'lg-');
    }

    @for $i from 1 through 20 {
      $width: percentage(5*0.01) * $i;
      @include col-mixin($i*5, $width, 'lg-', '-proc');
    }

    @include settings("lg-")
  }



}

@media screen and (max-width: 991.98px) {

  .md-block {
    display: block !important;
  }

  .md-inline-block {
    display: inline-block !important;
  }

  .md-hide {
    display: none !important;
  }

  .row {
    @for $i from 1 through 12 {
      $width: percentage(math.div(1, 12)) * $i;
      @include col-mixin($i, $width, 'md-');
    }

    @for $i from 1 through 20 {
      $width: percentage(5*0.01) * $i;
      @include col-mixin($i*5, $width, 'md-', '-proc');
    }

    @include settings("md-")
  }



}

@media screen and (max-width: 767.98px) {

  .sm-block {
    display: block !important;
  }

  .sm-inline-block {
    display: inline-block !important;
  }

  .sm-hide {
    display: none !important;
  }

  .row {
    @for $i from 1 through 12 {
      $width: percentage(math.div(1, 12)) * $i;
      @include col-mixin($i, $width, 'sm-');
    }

    @for $i from 1 through 20 {
      $width: percentage(5*0.01) * $i;
      @include col-mixin($i*5, $width, 'sm-', '-proc');
    }

    @include settings("sm-")
  }



}



@media screen and (max-width: 575.98px) {

  .xsm-block {
    display: block !important;
  }

  .xsm-inline-block {
    display: inline-block !important;
  }

  .xsm-hide {
    display: none !important;
  }

  .row {
    @for $i from 1 through 12 {
      $width: percentage(math.div(1, 12)) * $i;
      @include col-mixin($i, $width, 'xsm-');
    }

    @for $i from 1 through 20 {
      $width: percentage(5*0.01) * $i;
      @include col-mixin($i*5, $width, 'xsm-', '-proc');
    }

    @include settings("xsm-")
  }



}

//kolumna flex
.column {
  display: flex;
  flex-direction: column;

  > * {
    flex-grow: 0;
    flex-shrink: 0;
  }

  > .grow {
    flex-grow: 1;
    flex-shrink: 1;
    min-height:1px;
  }
}
