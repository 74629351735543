
input.with-unmask {

  & + .unmask-button {
    position: absolute;
    vertical-align: -10px;
    margin-left: -30px;
    display: inline-block;
    color: #999;
    opacity: 0.3;
    cursor: pointer;
    overflow: hidden;

    &:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z' /%3E%3C/svg%3E");
      display: inline-block;
      height: 1em;
      width: 1em;
      vertical-align: -0.1em;
    }

    &:hover {
      opacity: 0.8;
    }

  }

}