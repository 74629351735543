.grid .grid-body-container .grid-body-wrapper {


  &::-webkit-scrollbar {
    width: 17px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 1px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.08);
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

}