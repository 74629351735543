.app-form {

  .checkbox-label {

    &:after {
      content: "";
      clear: both;
      display: table;
    }

    //line-height: 3rem;
    //min-height: 3rem;
    //font-size: 1.3rem;

    input.checkbox-input {
      margin-left: 0.5rem;
      display: inline-block;
      float: left;
      margin-right: 1rem;
      line-height: 3rem;
      min-height: 3rem;
    }

    div {
      line-height: 2.2rem;
      font-size: 1.3rem;
      overflow: auto;
      padding-top: 0.3rem;
    }

  }

  &.small {
    .checkbox-label {
      //line-height: 2.2rem;
      //min-height: 2.2rem;

      input.checkbox-input {
        line-height: 2.4rem;
        min-height: 2.4rem;
        margin-right: 0.5rem;
      }

      div {
        line-height: 2.1rem;
        font-size: 1.2rem;
        overflow: auto;
        padding-top: 0.1rem;
      }
    }
  }


}