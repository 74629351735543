
@import "rightslider";
@import "appworkspace";

.modal.dialog {
  display: none;

  &.active {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(236, 236, 236, 0.63);
    z-index: 9999;

    .modal-dialog {

      background-color: #fff;
      margin: 20px auto 0 auto;
      width: 720px;
      border: 1px solid rgba(140, 140, 140, 0.3);
      display: flex;
      flex-direction: column;

      max-height: calc(100% - 40px);

      &:after {
        content: "";
        display: table;
        clear: both;
      }

      > .app-form {
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }

      .modal-dialog-header {
        border-bottom: 1px solid rgba(140, 140, 140, 0.2);
        padding: 15px 20px;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;

        > div {
          justify-content: center;
        }

        .modal-dialog-title {
          flex-grow: 1;
          font-size: 16px;
          font-weight: 400;
          color: #333;

          .modal-dialog-super-title {
            color: #3989b3;
            font-weight: 500;
            font-size: 10px;
            margin-bottom: 2px;
          }
        }

        .modal-dialog-closeicon {
          color: #aaa;
          cursor: pointer;

          svg {
            height: 18px;
            width: 18px;
          }

          &:hover {
            color: #000;
          }
        }
      }

      .modal-dialog-submit-bottom, .modal-dialog-submit-top {
        padding: 10px 15px;
        background-color: #fbfbfb;

        clear: both;
        flex-grow: 0;
      }

      .modal-dialog-submit-top {
        border-bottom: 1px solid rgba(140, 140, 140, 0.3);
      }

      .modal-dialog-submit-bottom {
        border-top: 1px solid rgba(140, 140, 140, 0.3);
      }

      .modal-dialog-content {
        float: left;
        width: 100%;
        //max-height: 400px;
        overflow: auto;
        flex-grow: 1;
        max-height: 100%;

        .message {
          line-height: 20px;
          font-size: 13px;
          padding: 20px;
        }

        &.dialog-list {
          padding: 0;

          .row {
            padding: 10px 15px;
            border-bottom: 1px solid #e8e8e8;

            &.selected {
              background-color: #4a87b6;
              color: #fff;
            }

          }

        }
      }
    }
  }


  form {
    position: relative;
    width: 100%;
  }

  &.wide {
    > .modal-dialog {
      max-width: 1120px;
      width: 90%;
    }
  }

  &.narrow {
    & > .modal-dialog {
      max-width: 480px;
      width: 90%;
    }
  }

  &.fullheight {
    .modal-dialog-content {
      flex-grow: 1;
    }

    .modal-dialog {
      height: calc(100% - 40px);
    }

    .app-form {
      flex-grow: 1;
    }
  }

  .error-dialog-content {
    margin: 20px;

    .icon {
      margin-right: 20px;

      svg {
        width: 48px;
        height: 48px;
      }

      &.error {
        color: #b12a28;
      }

      &.information {
        color: #0c74a8;
      }

      &.warning {
        color: #d87007;
      }

    }

    .message {
      font-size: 13px;
      line-height: 20px;
    }

  }


}