.app .app-topbar .app-topbar-user {

  height: $app__topbar_height;
  display: flex;
  align-items: center;

  padding-left: 10px;
  cursor: pointer;

  .name {
    display: inline-block;
    font-size: 13px;
    margin: 0 0px 0 5px;
    color: #333;
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0,0,0, 0.2);
    /* vertical-align: 1px; */
    /* margin: 0 0.1em 0 0.5em; */
    display: inline-block;
    float: right;

    margin-left: 5px;
    margin-right: 10px;
  }

  &:hover {
    background-color: rgba(#000, 0.05);

    &::after {
      border-top-color: rgba(0,0,0, 0.3);
    }
  }

}

.topbar-userdrop {

  .data {
	position: relative;
	font-size: 13px;
	line-height: 16px;
	padding: 10px 10px;
	min-width: 280px;
	overflow: auto;

	.avatar {
	  float: left;
	  margin-right: 20px;
	}

	.name {
	  font-weight: 500;
	  margin-bottom: 5px;
	  margin-top: 10px;
	}
  }

  .userdrop-toolbar {
	padding: 10px;
	text-align: right;
	border-top: 1px solid #ddd;
	background-color: rgba(0, 0, 0, 0.02);
  }

  .userdrop-lang-change {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.02);
    border-top: 1px solid #ddd;

    .svg-icon {
      color: #999;
      font-size: 20px;
      vertical-align: -5px;
    }

    a {
      color: #666;

      &:hover {
        color: #222;
      }
    }
  }


}