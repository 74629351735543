.grid {
  .grid-header-container {

    &.with-groups {
      height: 62px;

      a, span {
        line-height: 37px;
      }

      &.handler-drag {
        height: 60px;
        line-height: 60px;
      }

      table {
        height: 60px;
      }

      .resize-handle {
        height: 60px;
      }

      .groups-row {
        th {
          padding: 5px;
          font-size: 1.1rem;
          font-weight: 400;
          border-bottom: 1px solid #ddd;
        }

        th.empty {
          border-bottom: 1px solid transparent;
        }
      }
    }

  }
}

