.contract-editor-modal {

  .title-row input {
    width: 90%;
    font-size: 20px;
    line-height: 30px;
    padding: 0;
    border: 0;
    border-bottom: 1px solid transparent;
    outline: 0;

    &:focus {
      border-bottom: 1px solid #0f84c0;
    }
  }

  .roosterjs-toolbar {
    border-bottom:0px !important;
  }
}

.contract-editor {
  font-size: 1.3rem;
  font-weight: 400;
  color: #333;
  padding: 0px 20px;
  line-height: 1.9rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;

  &.edit-mode {
    .row:hover {
      .indent-toolbar {
        display: none;
      }

      .move-toolbar {
        display: none;
      }
    }

    .content {
      cursor: default;

      &:hover {
        border: 1px solid transparent;
      }
    }

    .insert-toolbar:hover {
      .wrap {
        display: none;
      }
    }

    .content + .editor {
      margin-top: 10px;
      border: 1px solid #0f84c0;
    }

    .cell.active {
      .content {
        border: 1px solid #ddd;
      }
    }

  }


  .editor-toolbar {
    text-align: right;
  }

  .editor-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    z-index: 20;
    height: 100%;

    .editor {
      margin-top: 10px;

    }
  }

  .row {
    border-bottom: 1px solid #eee;
  }

  input[type=text], select {
    padding: 5px;
    font-size: 1.3rem;
    border: 1px solid transparent;
    width: 100%;
    outline: 0;

    &:focus, &:hover {
      border: 1px solid #0f84c0;
    }
  }

  .cell {
    padding: 5px;
  }

  .num {
    padding: 10px;
    text-align: right;
    font-weight: 500;
  }

  .content {
    padding: 5px;
    border: 1px solid transparent;
    cursor: pointer;
    min-height: 40px;

    &:hover {
      border: 1px solid #0f84c0;
    }

    .merge-field,
    *[data-mergefield] {
      background-color: #ececec;
      color: inherit;
      text-decoration: none;

      &:before {
        content: '\00ab';
      }

      &:after {
        content: '\00bb';
      }
    }
  }

  .insert-toolbar {
    font-size: 16px;
    text-align: center;
    //background-color: rgba(34,222,23, 0.3);

    position: relative;
    z-index: 2;
    transform: translateY(-50%);

    height: 10px;
    margin-bottom: -10px;

    .wrap {
      display: none;
    }

    &:hover {

      padding-top: 5px;
      height: 40px;
      margin-bottom: -40px;
      transform: translateY(-50%);

      .wrap {
        margin-left: auto;
        margin-right: auto;
        display: inline-block;
      }

    }
  }

  .col-1 {
    position: relative;
  }

  .indent-toolbar {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px 0;
    display: none;
  }

  .row:hover {
    .indent-toolbar {
      display: block;
    }

    .move-toolbar {
      display: block;
    }
  }

  .move-toolbar {
    margin: 5px 0;
    display: none;
  }

  .icon-btn {
    border: 1px solid #eee;
    font-size: 13px;
    font-weight: 400;
    color: #333;
    user-select: none;
    cursor: pointer;
    min-width: 26px;
    height: 26px;
    display: inline-block;
    text-align: center;
    margin-right: 5px;

    & > span {
      display: inline-block;
      margin: 0 8px;
      vertical-align: -2px;
    }

    &.disabled {

      cursor: default;
      border: 1px solid #efefef;

      &:hover {
        border: 1px solid #efefef;
      }

      svg {
        opacity: 0.2;
      }

    }

    &:hover {
      border: 1px solid #aaa;
      color: #333;
    }
  }


  .add-sec, .add-par {
    color: #333;
    height: 30px;
    width: 30px;
    display: inline-block;
    padding: 4px;
    border: 1px solid #ddd;
    margin: 0 5px;
    background-color: #fff;
    user-select: none;
    cursor: pointer;
    font-size: 18px;

    b {
      color: #0f84c0;
      font-weight: normal;
    }

    &:hover {
      border: 1px solid #aaa;
      color: #333;
    }

  }


  .contract-editor-toolbar {
    position: relative;
    z-index: 10;
    margin-top: 10px;
    text-align: right;
    padding-bottom: 10px;

    .button + .button {
      margin-left: 10px;
    }

  }
}
