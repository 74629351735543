.grid {

  .col-title {
    cursor: default;
  }

  td.editor {
    padding: 0px 0px 0px 0px !important;

    select {
      width: 100%;
      padding: 5px 2px 6px 5px;
      border: 1px solid transparent;
      background-color: transparent;

      outline: none;

      &:not([disabled]):hover, &:not([disabled]):focus {
        border: 1px solid $forms__borderColor--focus;
        background-color: #fff;
      }

    }

    input[type=text] {
      width: 100%;
      padding: 4px 2px 4px 5px;
      border: 1px solid transparent;
      background-color: transparent;
      height: 32px;
      outline: none;

      &:not([disabled]):hover, &:not([disabled]):focus {
        border: 1px solid $forms__borderColor--focus;
        background-color: #fff;
      }

    }

    select.error, input[type=text].error {
      border: 1px solid $forms__errorColor;
    }

  }
}