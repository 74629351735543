.employee-work-tab-label {

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .status {
    flex-grow: 0;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    background-color: #1aa107;
    padding: 4px 6px;
    border-radius: 2px;
  }

  .company-name {
    flex-grow: 1;
    font-weight: 400;
    font-size: 13px;
    text-align: right;
  }

  .client-name {
    font-size: 12px;
    color: #333;
    flex-basis: 100%;
    font-weight: 400;
    padding-top: 10px;
  }

  .job-offer-name {
    font-size: 13px;
    color: #000;
    flex-basis: 100%;
    font-weight: 400;
    padding-top: 4px;
  }

  .dates {
    padding-top: 10px;
    flex-basis: 100%;
    text-align: right;
    color: #666;
    font-size: 11px;
    b {
      font-weight: 500;
    }

    &.active-dates {
      color: #333;
    }
  }
}