.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 1;

  box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.3);
}
.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.qed-req {
  border: 1px solid rgba(0,0,0,0.2);
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  background-color: #fff;
  position: relative;
  cursor: move;

  & + .qed-req {
    margin-top: 10px;
  }

  &.gu-mirror {
    border: 1px solid #0c74a8;
  }


  .qed-req__client {
    flex-grow: 0;
    font-size: 12px;
    display: block;
  }

  .qed-req__company {
    flex-grow: 1;
    font-size: 12px;
    display: block;
    text-align: right;
  }


  .qed-req__n {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 8px 0;

    .qed-req__empl {
      font-size: 13px;
      font-weight: 500;
      flex-basis: 100%;
      width: 100%;
      display: block;

      a {
        color: #000;
      }
    }

    .qed-req__dates{
      margin-left: auto;
      white-space: nowrap;
      line-height: 1.4em;
      font-size: 13px;

      .qed__req-date {
        display: inline-block;

        border: 1px solid #ddd;
        min-width: 20px;
        padding: 0 5px;
      }
    }
  }

  .qed-req__comment {
    margin-top: -6px;
    margin-bottom: 8px;
    width: 100%;
    font-size: 12px;
  }


  .qed__req-creator {
    width: 100%;
    text-align: right;
    color: #666;
    margin-top: 5px;
    font-size: 11px;
  }

  .qed__req-toolbar {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px 10px;
    display: none;
  }

  &:hover {
    &:not(.gu-mirror){
      .qed__req-toolbar {
        display: block;
      }

    }
  }

}

.qed {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  .qed__reqs {
    margin-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    .qed_req + .qed_req {
      margin-top: 10px;
    }

  }




  .qed__floors {
    margin-top:10px;
    font-size: 13px;
    width:100%;
    border-width: 1px 1px 0 1px;
    border-color: #ddd;
    border-style: solid;
    margin-bottom: 30px;

    .qed__floor {
      display: flex;
      border-bottom: 1px solid #ddd;
      align-items: center;

      &:nth-child(even){
        background-color: #fafafa;
      }

      .qed__floor-label {
        width: 12%;
        flex-basis: 12%;
        flex-grow: 0;
        padding: 10px;
        font-weight: 500;
        flex-shrink: 0;
      }

    }

    .qed__rooms {
      align-items: center;
      flex-grow: 1;
      border-left: 1px solid #ddd;
    }


  }

  .qed__room {
    display: flex;
    border-bottom: 1px solid #ddd;
    align-items: center;
    flex-grow: 1;

    &:nth-child(even){
      background-color: rgba(0,0,0,0.04);
    }

    &:last-child {
      border-bottom: 0;
    }

    .qed__room-label {
      width: 12%;
      flex-grow: 0;
      padding: 10px;
      font-weight: 500;
      flex-shrink: 0;
    }

    .qed__places {
      flex-grow: 1;
      border-left: 1px solid #ddd;
    }

    .qed__place {
      min-height: 24px;
      border-bottom: 1px solid #ddd;

      &.ex-over {
        outline: 2px solid #0c6a9b;
      }

      &:nth-child(even){
        background-color: rgba(0,0,0,0.04);
      }

      &:last-child {
        border-bottom: 0px;
      }
    }


  }





}

.qed-addr{
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  font-size: 13px;
  cursor: move;
  user-select: none;

  padding-bottom: 12px;

  &.gu-mirror {
    background-color: #fff;
    .qed-addr__toolbar {
      opacity: 0 !important;
    }

  }
  .qed-addr__empl {
    width:25%;
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 500;
    padding: 10px 7px;
    a {
      color: #000;
    }
  }

  .qed-addr__client {
    width:14%;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .qed-addr__company {
    width:14%;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .qed-addr__date {
    width:14%;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .qed-addr__comment {
    padding: 10px 7px;
  }

  .qed-addr__creator {
    position: absolute;
    font-size: 11px;
    color: rgba(0,0,0,.6);
    bottom: 4px;
    left: 7px;
  }

  .qed-addr__toolbar {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    padding-left: 20px;
    margin-top: 8px;
  }

  &:hover {
    .qed-addr__toolbar {
      opacity: 1;
      pointer-events: auto;
    }
  }

}

.qed-addr + .qed-addr {
  border-top: 1px dotted rgba(0,0,0,0.1);
}

.qed__out {
  margin-top: 10px;
  align-items: center;
  min-height: 40px;
  border: 2px dotted #ddd;
  text-align: center;
  margin-left: 20px;

  &.ex-over {
    border: 2px solid #0c6a9b;
    background-color: #e5eff8;
  }

  .qed-addr.gu-transit {
    display: none;
  }
}