/*!package forms */

$forms__fontFamily: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", arial, sans-serif !default;

$forms__borderColor: #dedede !default;
$forms__borderColor--disabled: #efefef !default;
$forms__borderColor--hover: #aaa !default;
$forms__borderColor--focus: #3f83b4 !default;

$forms__errorColor: #b12a28 !default;

$forms__labelColor: #666;
$forms__helpColor: #888;

@import "input_text";
@import "select";
@import "checkbox";
@import "pikaday";
@import "password_unmask";
@import "dropzone";
@import "select2";

.app-form {
  min-height: 1px;
  
  .app-subform-title {
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #dedede;
    font-weight: 600;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    min-inline-size: unset;
    max-width: 100%;
  }

  &.small {
    .control-group {
      .control-label {
        display: block;
        font-size: 1.1rem;
        margin-bottom: 0.2rem;
        font-weight: 400;
        color: $forms__labelColor;
      }

      .help {
        font-size: 1.1rem;
        margin: 0.4rem 0.2rem;
        display: block;
        color: $forms__helpColor;
      }

      .errors {
        font-size: 1.1rem;
      }
    }
  }


  .control-group {
    & + .control-group {
      margin-top: 1rem;
    }

    .control-label {
      display: block;
      font-size: 1.3rem;
      margin-bottom: 0.3rem;
      font-weight: 400;

      .control-label-req::after {
        content: '*';
        color: $forms__errorColor;
      }

    }

    .errors {
      list-style: none;
      padding-left: 0;
      color: $forms__errorColor;
      font-size: 1.2rem;
      margin: 0.4rem 0.2rem;
      line-height: 1.7rem;
    }

    .help {
      font-size: 1.2rem;
      margin: 0.4rem 0.2rem;
      display: block;
    }

    //box z paddingiem na gorze równym wyoskości label'a

    .checkbox-group, radiogroup {
      margin-left: 10px;
      max-height: 300px;
      overflow: auto;
      display: block;

      label {
        display: block;
        line-height: 2.2rem;
        font-size: 1.2rem;

        & > div {
          overflow: auto
        }

        .checkbox-group-desc {
          font-size: 11px;
          color: $forms__helpColor;
          margin-bottom: 3px;
        }

        input[type=checkbox], input[type=radio] {
          vertical-align: -0.18em;
          margin-right: 5px;
          float: left;
          margin-top: 5px;
        }
      }
    }


    &.error {
      input.text-input, textarea, select {
        border-color: $forms__errorColor;
      }

    }

  }

  .btn-box {
    padding-top: 1.8rem;

    .button {
      margin-top: 1px;
      height: calc(3rem + 2px);
      line-height: 3rem;
      width: 100%;
      text-align: center;
    }
  }

}
