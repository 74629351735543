@import "../styler/vendor/vendor";
@import "employee_declarations";
@import "roostergui";
@import "box-link";
@import "import-result-item";
@import "month-picker";
@import "file-browser";
@import "loader-font";
@import "commentsdatabag";
@import "contract-editor";
@import "default-table";
@import "work";
@import "notifications";
@import "qed";
@import "dashboard";
@import "extrnal_application";
@import "olap-report";

.badge-in-grid {
  display: inline-block;
  padding: 1px 7px;
  font-weight: 400;
  color: #fff;
  border-radius: 3px;
}

.radiogroup label {
  font-size: 13px;
}

.databag .vert-item .vert-value {
  min-height: 1.6em;
}

.app-form .control-group .checkbox-group label,
.app-form .control-group radiogroup label {
  font-size: 13px;
}

*.loading {
  > *:not(.loader) {
    opacity: 0.5;
  }
}

@include font_loader('.databag');

.tabs-column {
  .tabs-content.with-padding {
    padding: 10px 20px;
  }
}
/*
.loader {
  background-color: transparent !important;
  z-index: 10000;

  .loader-svg {
    height: 80px;
    width: 80px;
    position: sticky;
    top: 100px;
  }
}
*/

.grid table td svg.svg-inline--mdi, .grid table th svg.svg-inline--mdi {
  vertical-align: -.25em;
  margin-right: 5px;
}

svg.svg-inline--mdi.green {
  color: $alerts__successColor !important;
}

svg.svg-inline--mdi.red {
  color: $alerts__errorColor !important;
}

svg.svg-inline--mdi.orange {
  color: #e29e44 !important;
}

svg.svg-inline--mdi.gray {
  color: #999 !important;
}

svg.svg-inline--mdi.darkgrey {
  color: #666 !important;
}


.databag .row .value .alert-block {
  margin-bottom: 0px;
}

.app-form input.text-input:disabled, .app-form textarea:disabled {
  border: 1px solid #efefef;
  border-color: #efefef !important;
  background: #fafafa;
  color: #bdbdbd;
}

.app-workspace-header .alert-block {
  margin-top: 5px;
  margin-bottom: 0;
}

.download-file-value {
  color: #666;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  padding: 3px 8px 3px 3px;
  border: 1px solid #eee;

  .svg-inline--mdi {
    font-size: 18px;
  }

  &:hover {
    text-decoration: none;
    border: 1px solid #aaa;
    background-color: #fafafa;
    color: #555;

    .svg-inline--mdi {
      color: #555;
    }
  }
}


.dropdown-container.dropdown-list {
  text-align: left;
}


.app-workspace-header {
  .title .id {
    padding: 0 10px 2px;
    background-color: #e9f1fb;
    border: 1px solid #85b7ce;
    margin-right: 10px;
    border-radius: 3px;

  }
}


.metaitem {
  .metaitem-label {
    .svg-inline--mdi {
      font-size: 18px;
    }
  }

  & + .metaitem {
    padding-left: 10px;
  }

  .editlink {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    color: #999;

    .svg-inline--mdi {
      font-size: 16px;
      vertical-align: -.25em;

    }
  }

  &:hover {

    .editlink {
      opacity: 1;
      pointer-events: all;

      &:hover {
        color: #333;
      }
    }
  }

}


.postal-code-data {
  padding: 0.7rem 0.5rem;
  font-size: 13px;
  line-height: 22px;
  border-bottom: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  background-color: #fafafa;
  position: relative;
}

.input-col-divider {
  padding-top: 24px;
  text-align: center;
  font-size: 1.3rem;
  color: #999;
}


.declaration-view {

  font-size: 13px;

  .row > div {
    padding-bottom: 3px;
    margin-bottom: 6px;

    &.lbl {
      color: #666;
      border-bottom: 1px solid #ddd;
    }
  }

  .title {
    text-align: center;
    font-size: 2em;
    font-weight: 500;
  }

  .section-header {
    font-size: 1.5em;
    font-weight: 500;

  }

  b {
    font-weight: 500;
  }


}

.app-body .dropdown-container {
  font-size: 1.3rem;
}

.app-form .compact-checkboxes {

  .checkbox-label input.checkbox-input {
    line-height: 1.0rem;
    min-height: 1.0rem;
  }

  .checkbox-label div {
    overflow: unset;
    float: left;
    line-height: 1.0rem;
    padding-top: 0;
  }

  .checkbox-label input:disabled + div {
    opacity: 0.7;
  }


}

.modal.dialog.active .modal-dialog .modal-dialog-content {
  max-height: 100%;
}

.app-workspace .app-workspace-header .title-row .title-col .subtitle {
  /* margin-top: 3px; */
  font-size: 20px;
  color: #666;
  /* margin-bottom: 4px; */
  display: inline-block;
  /* padding: 4px 6px; */
  /* background-color: #9da9b5; */
  font-weight: 300;
  float: left;
  line-height: 30px;
  margin-left: 10px;
  margin-top: 0;

  &:before {
    content: '·';
    display: inline-block;
    margin-right: 5px;
    font-weight: 800;
    color: #ccc;
  }
}

.app-workspace .app-workspace-header .title-row .title-col .title {
  float: left;
}


.app-workspace .app-workspace-header .title-row .icon-col .icon-wrapper {
  margin-right: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px 10px 3px 0;
  height: 100%;
}


.details-tab-toolbar {
  margin: 10px 20px 0 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-list > .dropdown-group-header {
  font-size: 11px;
  font-weight: 600;
  border-top: 1px solid #ddd;

  &:first-child {
    border-top: 0;
  }
}

.app-body .dropdown-container {
  overflow: auto;
}

.app-body {
  .app-form .app-appendable-subform-title {
    font-size: 13px;
  }
}


.selected-items-list {
  .selected-item {
    padding: 10px 20px;
    border-bottom: 1px solid rgba(140, 140, 140, 0.2);
    font-size: 13px;
    line-height: 1.4em;

    b {
      font-weight: 500;
    }
  }
}

.modal-dialog-content > .alert-block {
  margin: 10px 20px 0px;
}

.calc-log {
  font-size: 13px;
  line-height: 1.8em;

  .error {
    color: $forms__errorColor;
    font-weight: 500;
  }
}


.choose-list-item {
  display: block;
  color: #333;
  padding: 10px 20px;
  font-size: 13px;
  line-height: 18px;

  & + .choose-list-item {
    border-top: 1px solid #eee;
  }

  &:hover {
    text-decoration: none;
    background-color: #f9f9f9;
  }
}