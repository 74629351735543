.det-sidebar {
  height: 100%;
  width: 30%;
  max-width: 400px;
  border-left: 1px solid #eee;

  display: flex;
  flex-direction: column;
  min-height: 1px;
}

.next-contact-date {
  padding: 10px;
  border-bottom: 1px solid #eee;
  flex-grow: 0;

  .label {
    font-size: 13px;
    margin-bottom: 5px;
    color: #666;
  }

  .date {
    padding: 5px;
    border: 1px solid #dedede;
    font-size: 13px;
    display: inline-block;
    cursor: pointer;

    &:hover {
      border: 1px solid #3f83b4;
    }

    &.empty {
      color: #999;
    }
  }
}

.mark-inline {
  border-color: var(--border-color, #000);
  background-color: var(--bg-color, rgba(0,0,0,.2));
  display: inline-block;
  margin-right: 10px;
  padding: 3px 5px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  white-space: nowrap;
}

.det-sidebar .databag {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 1px;

  & > .row {
    flex-grow: 0;
    padding: 0 10px;
  }

  .header-row {
    padding: 0 10px 0 10px;
    border-bottom: 0;

    .header {
      font-size: 1.3rem;
      padding-bottom: 5px;
    }
  }

  .comments-list {
    flex-grow: 1;
    overflow: auto;
    min-height: 1px;
    border-top: 1px solid #eee;
    margin-top: 5px;

    .comment-row {
      padding: 0 10px;
      flex-wrap: nowrap;

      time {
        white-space: nowrap;
      }

      data {
        white-space: nowrap;
      }

      .comment {
        margin-left: 10px;
        padding: 0;
        font-size: 1.3rem;
        border: 0;
      }

      .comment-meta {
        padding-bottom: 5px;
        font-size: 1.2rem;
      }

      .comment-text {
        padding: 5px;
        border: 1px solid #eee;
        line-height: 1.4em;
      }
    }
  }

}

.metaitem {
  a.mark-inline {
    padding-top:1px;
    padding-bottom: 1px;
    color: #333;
    &:hover {
      text-decoration: line-through;
    }
  }
}