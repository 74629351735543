
@use "sass:list";

$tabs__fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$tabs__fontSize: 1.3rem !default;

$tabs__accentColor: #1194d8;

$tabs__item_padding: 10px 1px !default;
$tabs__item_spacing: 15px !default;

$tabs__item_color: #333 !default;
$tabs__item_font: normal normal 400 list.slash($tabs__fontSize, 1.6rem) $tabs__fontFamily !default;
$tabs__item_borderBottom: 2px solid transparent !default;

$tabs__item_color--active: #333 !default;
$tabs__item_font--active: normal normal 600 list.slash($tabs__fontSize, 1.6rem) $tabs__fontFamily !default;
$tabs__item_borderBottom--active: 2px solid $tabs__accentColor !default;

$tabs__item_color--hover: #333 !default;
$tabs__item_font--hover: normal normal 400 list.slash($tabs__fontSize, 1.6rem) $tabs__fontFamily !default;
$tabs__item_borderBottom--hover: 2px solid rgba($tabs__accentColor, 0.4) !default;

.tabs {
  display: flex;

  a {
    text-decoration: none;
    display: inline-block;

    padding: $tabs__item_padding;

    color: $tabs__item_color;
    font: $tabs__item_font;
    border-bottom: $tabs__item_borderBottom;
    margin-right: $tabs__item_spacing;

    &.active {
      color: $tabs__item_color--active !important;
      font: $tabs__item_font--active !important;
      border-bottom: $tabs__item_borderBottom--active !important;
    }

    &:hover {
      font: $tabs__item_font--hover;
      color: $tabs__item_color--hover;
      border-bottom: $tabs__item_borderBottom--hover;
      text-decoration: none;
    }
  }
}