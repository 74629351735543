.box-link {

  border: 1px solid #e8e8e8;
  padding: 10px;
  position: relative;
  display: block;
  transition: all 0.1s ease-in-out;
  height: calc(100% - 20px);

  &:hover {
    text-decoration: none;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);

    .arrow-right {
      right: 10px;
      opacity: 1;
    }

  }

  .arrow-right {
    position: absolute;
    top: 10px;
    right: 15px;
    height: 18px;
    width: 18px;
    color: $forms__borderColor--focus;
    opacity: 0.5;
    transition: all 0.1s ease-in-out;
  }

  .box-title {
    font-size: 14px;
    font-weight: 400;
    color: #333;
    margin-bottom: 10px;
    margin-right: 30px;
  }

  .box-summary {
    font-size: 12px;
    font-weight: 400;
    color: #666;
  }

}