$alerts__padding: 0.7rem 1.3rem !default;
$alerts__border: 1px solid #dedede !default;

$alerts__fontSize: 1.3rem !default;
$alerts__fontFamily: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$alerts__iconSize: 24px !default;
$alerts__iconVerticalAlign: -.7rem !default;

$alerts__accentBorderWidth: 4px !default;

$alerts__errorColor: #b12a28 !default;
$alerts__warningColor: #d87007 !default;
$alerts__noticeColor: #e2cc28 !default;
$alerts__successColor: #1aa107 !default;

.alert-block {
  display: block;
  font-family: $alerts__fontFamily;
  padding: $alerts__padding;
  border: $alerts__border;
  font-size: $alerts__fontSize;
  line-height: $alerts__iconSize;
  margin-bottom: 2rem;
  background-color: #fff;

  .svg-inline--mdi {
    width: $alerts__iconSize;
    height: $alerts__iconSize;
    display: inline-block;
    vertical-align: $alerts__iconVerticalAlign;
    margin-right: 1rem;
  }

  &.error, &.warning, &.notice, &.success {
    border-left-width: $alerts__accentBorderWidth;
    border-left-style: solid;
  }

  &.error {
    border-left-color: $alerts__errorColor;
    .svg-inline--mdi {
      color: $alerts__errorColor;
    }
  }

  &.warning {
    border-left-color: $alerts__warningColor;

    .svg-inline--mdi {
      color: $alerts__warningColor;
    }
  }

  &.notice {
    border-left-color: $alerts__noticeColor;

    .svg-inline--mdi {
      color: $alerts__noticeColor;
    }
  }

  &.success {
    border-left-color: $alerts__successColor;

    .svg-inline--mdi {
      color: $alerts__successColor;
    }

  }
}

.toast-container {
  z-index: 9999;
  position: fixed;
  bottom: 10px;
  right: 50px;
  width: 300px;

  .toast {
    transition: all 0.3s ease-in-out;
    transform: translateY(30px);
    opacity: 0;

    &.visible {
      transform: translateY(0px);
      opacity: 1;
    }

    &.hiding.visible {
      transform: translateY(30px);
      opacity: 0;
    }

  }
}
