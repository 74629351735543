$app__leftnav_fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$app__leftnav_width: 260px !default;
$app__leftnav_bgColor: #f8f8f8 !default; //#2b3e51 !default;
$app__leftnav_borderRight: 1px solid rgba(0,0,0,0.1) !default;

$app__leftnav_subtitle_color: rgba(0,0,0, 1) !default;
$app__leftnav_subtitle_font: normal normal 500 1.2rem/1.4rem $app__leftnav_fontFamily !default;
$app__leftnav_subtitle_padding: 9px 12px 10px !default;
$app__leftnav_subtitle_margin: 0px 0px 0px 0px !default;
$app__leftnav_subtitle_textTransform: uppercase !default;

$app__leftnav_accentColor: #1194d8 !default;
$app__leftnav_itemColor: #333333 !default;
$app__leftnav_iconColor: #333 !default;

$app__leftnav_item_iconSize: 24px !default;
$app__leftnav_item_iconVerticalAlign: -7px !default;
$app__leftnav_itemLink_padding: 8px 0 8px 0 !default;
$app__leftnav_itemLink_margin: 0 30px 0 12px !default;



$app__leftnav_item_shadow: inset 3px 0 rgba(#000, 0.15) !default;
$app__leftnav_item_iconColor: $app__leftnav_iconColor !default;
$app__leftnav_item_bgColor: transparent !default;
$app__leftnav_item_borderTop: 0px solid transparent !default;
$app__leftnav_item_borderBottom: 0px solid transparent !default;
$app__leftnav_itemLink_color: $app__leftnav_itemColor !default;
$app__leftnav_itemLink_font: normal normal 400 1.3rem/1.6rem $app__leftnav_fontFamily !default;


$app__leftnav_item_shadow--hover: inset 3px 0 rgba($app__leftnav_accentColor, 0.7) !default;
$app__leftnav_item_iconColor--hover: darken($app__leftnav_accentColor, 20%) !default;
$app__leftnav_item_bgColor--hover: rgba(0,0,0, 0.06) !default;
$app__leftnav_item_borderTop--hover: 0px solid trasparent !default;
$app__leftnav_item_borderBottom--hover: 0px solid trasparent !default;
$app__leftnav_itemLink_color--hover: $app__leftnav_itemColor !default;
$app__leftnav_itemLink_font--hover: normal normal 400 1.3rem/1.6rem $app__leftnav_fontFamily !default;


$app__leftnav_item_shadow--active: inset 3px 0 $app__leftnav_accentColor !default;
$app__leftnav_item_iconColor--active: darken($app__leftnav_accentColor, 20%) !default;
$app__leftnav_item_bgColor--active: rgba(71, 154, 210, 0.1) !default;
$app__leftnav_item_borderTop--active: 0px solid trasparent !default;
$app__leftnav_item_borderBottom--active: 0px solid trasparent !default;
$app__leftnav_itemLink_color--active: darken($app__leftnav_accentColor, 20%) !default;
$app__leftnav_itemLink_font--active: normal normal 500 1.3rem/1.6rem $app__leftnav_fontFamily !default;


.app .app-left-nav {
  flex-basis: $app__leftnav_width;
  background-color: $app__leftnav-bgColor;
  border-right: $app__leftnav_borderRight;

  overflow: auto;
  height: 100%;

  &.is-visible {
    z-index: 499;
  }

  dl {

    dt {
      color: $app__leftnav_subtitle_color;
      font: $app__leftnav_subtitle_font;

      border-top: 1px solid rgba(0,0,0,0.1);

      padding: $app__leftnav_subtitle_padding;
      margin: $app__leftnav_subtitle_margin;
      text-transform: $app__leftnav_subtitle_textTransform;
      background-color: rgba(0,0,0,0.03);
      display: block;
      cursor: pointer;

      div {
        cursor: pointer;
      }

      svg {
        float: right;
        display: none;
      }

      &.collapsed {
        &::after {
          transform: rotate(90deg);
        }
      }

      &::after {
        content: '';
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid rgba(0,0,0, 0.2);
        /* vertical-align: 1px; */
        /* margin: 0 0.1em 0 0.5em; */
        display: inline-block;
        float: right;
        margin-top: 4px;
        transition: all 0.5s ease-in-out;
      }

    }

    dd {
      transition: all 0.5s ease-in-out;
    }

    .collapsed + dd {
      max-height: 0px;
      overflow: hidden;
    }

    dd {
      &:last-child {
        border-bottom: none;
      }
    }


    ul {
      padding-left: 0;
      margin: 0;

      li {
        list-style: none;
        position: relative;

        border-top: $app__leftnav_item_borderTop;
        border-bottom: $app__leftnav_item_borderBottom;

        box-shadow: $app__leftnav_item_shadow;

        transition: all ease-in-out 0.2s;

        &:last-child {
          a {
            border-bottom: 1px solid transparent;
          }
        }

        a {
          text-decoration: none;
          display: block;

          font: $app__leftnav_itemLink_font;
          color: $app__leftnav_itemLink_color;
          padding: $app__leftnav_itemLink_padding;
          margin: $app__leftnav_itemLink_margin;

          .counter {
            float: right;
            background: #8e8e8e;
            padding: 2px 6px;
            display: inline-block;
            font-size: 11px;
            font-weight: 700;
            color: #fff;
            margin-top: -1px;
            border-radius: 2px;
          }
        }

        svg {
          color: $app__leftnav_item_iconColor;
          font-size: $app__leftnav_item_iconSize;
          vertical-align: $app__leftnav_item_iconVerticalAlign;

          display: inline-block;
          margin-right: 8px;
          height: 1em;
          width: 1em;
        }


        &:hover {
          box-shadow: $app__leftnav_item_shadow--hover;
          background-color: $app__leftnav_item_bgColor--hover;
          border-top: $app__leftnav_item_borderTop--hover;
          border-bottom: $app__leftnav_item_borderBottom--hover;

          a {
            color: $app__leftnav_itemLink_color--hover;
            font: $app__leftnav_itemLink_font--hover;
          }

          svg {
            color: $app__leftnav_item_iconColor--hover;
          }
        }


        &.active {
          box-shadow: $app__leftnav_item_shadow--active;
          background-color: $app__leftnav_item_bgColor--active;
          border-top: $app__leftnav_item_borderTop--active;
          border-bottom: $app__leftnav_item_borderBottom--active;

          a {
            color: $app__leftnav_itemLink_color--active;
            font: $app__leftnav_itemLink_font--active;
          }

          svg {
            color: $app__leftnav_item_iconColor--active;
          }
        }


      }

    }

  }


  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 1px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background-color: rgba(0, 0, 0, 0.15)
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      border-radius: 1px;
      background-color: rgba(0, 0, 0, 0.5)
    }
  }

}