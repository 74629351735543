svg:not(:root).svg-inline--mdi {
  overflow: visible;
}

.svg-inline--mdi {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  width: 1em;
  overflow: visible;
  vertical-align: -.2em;
}

.svg-inline--mdi {

  &.mdi-1x {
    font-size: 1em !important;
    vertical-align: -.1em !important;
  }

  &.mdi-2x {
    font-size: 2em !important;
    vertical-align: -.28em !important;
  }

  &.mdi-3x {
    font-size: 3em !important;
    vertical-align: -.35em !important;
  }

  &.mdi-4x {
    font-size: 4em !important;
    vertical-align: -.4em !important;
  }

}



.mdi-fw {
  text-align: center;
  width: 1.25em;
}

.mdi-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.mdi-ul > li {
  position: relative;
}

.mdi-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.mdi-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.mdi-pull-left {
  float: left;
}

.mdi-pull-right {
  float: right;
}

.mdi.mdi-pull-left,
.mdis.mdi-pull-left,
.mdir.mdi-pull-left,
.mdil.mdi-pull-left,
.mdib.mdi-pull-left {
  margin-right: .3em;
}

.mdi.mdi-pull-right,
.mdis.mdi-pull-right,
.mdir.mdi-pull-right,
.mdil.mdi-pull-right,
.mdib.mdi-pull-right {
  margin-left: .3em;
}

.mdi-spin {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear;
}

.mdi-pulse {
  -webkit-animation: mdi-spin 1s infinite steps(8);
  animation: mdi-spin 1s infinite steps(8);
}

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.mdi-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mdi-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.mdi-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.mdi-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.mdi-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.mdi-flip-horizontal.mdi-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

:root .mdi-rotate-90,
:root .mdi-rotate-180,
:root .mdi-rotate-270,
:root .mdi-flip-horizontal,
:root .mdi-flip-vertical {
  -webkit-filter: none;
  filter: none;
}