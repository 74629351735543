table.default {
  font-size: 13px;
  width: 100%;
  border-top:1px solid #ddd;
  table-layout: fixed;

  th {
    border-top:1px solid #ddd;
    white-space: nowrap;
    padding: 12px 5px;
    overflow: hidden;
    position: relative;
    font-weight: normal;
    border-bottom: 1px solid #ddd;
    background-color: #fafafa;
  }

  td {
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.app-form + .app-form {
  margin-top: 10px;
}