
.dashboard-widget {

  border: 1px solid #e8e8e8;

  .handle {
    cursor: move;
  }

  .header-row {
    margin-bottom: 0px;

    .icon {
      margin-left: 10px;
      /* background-color: #3985d2; */
      /* text-align: center; */
      padding: 10px 5px 10px 0;
      /* margin-top: -10px; */
      /* margin-bottom: -6px; */
      /* margin-right: 10px; */
      /* border-radius: 3px; */
      /* color: #444; */


      .svg-inline--mdi {
        font-size: 24px;
        color: #649dd0;
        pointer-events: none;
      }
    }

    .title {
      font-size: 14px;
      //padding-top: 6px;
      font-weight: 400;
      color: #333;
    }


  }

  .content {
    font-size: 1.3rem;


    position: relative;

    &.resizable {
      position: relative;
      height: 220px;

      .resize-handle {
        cursor: s-resize;
        z-index: 20;
        position: absolute;
        bottom: -2px;
        width: 100%;
        height: 5px;

      }

      .data {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
        padding: 0px 10px;
      }

    }
  }

}


.dashboard-widget.dashboard-num {

  &.green {
    .dashboard-num__num {
      background-color: #e0f7df;
      color: #3e743c;
    }
  }

  &.orange {
    .dashboard-num__num {
      background-color: #f7efdf;
      color: #d7591e;
    }
  }

  &.blue {
    .dashboard-num__num {
      background-color: #dfeef7;
      color: #3a7ca2;
    }
  }

  &.purple {
    .dashboard-num__num {
      background-color: #eedff7;
      color: #64238a;
    }
  }

  &.red {
    .dashboard-num__num {
      background-color: #f7dfdf;
      color: #a93636;
    }
  }

  &.teal {
    .dashboard-num__num {
      background-color: #e4fdf3;
      color: #46af86;
    }
  }

  .dashboard-num__num {
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
    margin: 8px 16px 8px 8px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: #cfe3ce;

    height: 64px;
    width: 64px;
    position: relative;

    .dashboard-num__icon {
      position: absolute;
      bottom:0;
      right: 0;
      font-size: 24px;
      color: #309f14;

    }
  }

  .dashboard-num__title {
    color: #333;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 6px;
  }

  .dashboard-num__linkwrap {
    font-size: 14px;
    font-weight: 600;
    color: #666;
  }
}