$grid__buttons_fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

@if variable-exists(buttons__fontFamily) {
  $grid__buttons_fontFamily: $buttons__fontFamily;
}

$grid__buttons_regular: (
        'font': normal normal 400 1.2rem/2.8rem $grid__buttons_fontFamily,
        'height': 3rem,
        'padding': 0 1.2rem,
) !default;

@if variable-exists(buttons__regular) {
  $grid__buttons_regular: $buttons__regular;
}

.grid .grid-toolbar {

  background-color: #fff;
  height: 50px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;

  .grid-toolbar-left {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-grow: 1;
  }

  .separator {
    width: 1px;
    margin: 8px 10px;
    background-color: #ddd;
    align-self: stretch;

    &.no-left {
      margin-left:0;
    }

    &.no-right {
      margin-right:0;
    }
  }

  .full-button {
    align-self: stretch;
    vertical-align: middle;
    padding: 13px 8px;
    cursor:pointer;

    svg {
      color: #0c6a9b;
      font-size: 24px;
    }

    &:hover {
      background-color: #f4f4f4;
    }

    &.filtered {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 10px;
        right: 8px;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: #1aa107;
      }
    }
  }


  .grid-paginator {
    display: flex;
    align-items: center;

    .active {
      span {
        display: inline-block;
        font: map-get($grid__buttons_regular, 'font');
        height: map-get($grid__buttons_regular, 'height');
        padding: map-get($grid__buttons_regular, 'padding');
        border: 1px solid transparent;
        font-weight: 600;
      }
    }

    ul {
      margin: 0;
      padding:0;
      display: flex;
      align-items: center;
      list-style: none;

      li {
        margin-right: 5px;
      }

      li.previous-page, li.next-page {

        a {
          background-repeat: no-repeat;
          background-position: center;
          background-size: 37%;

          span {
            color: transparent;
            display: inline-block;
            width: 4px;
          }
        }
      }

      & > li.next-page {

        a {
          background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 256 256' xml:space='preserve'%3E%3Cpolygon points='79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128'/%3E%3C/svg%3E");
        }

      }

      & > li.previous-page {

        a {
          background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 256 256'%3E%3Cpolygon points='207.093,30.187 176.907,0 48.907,128 176.907,256 207.093,225.813 109.28,128'/%3E%3C/svg%3E%0A");
        }

      }

    }

    .info {
      margin-left: 10px;
      line-height: 1;
    }


  }

  .selected-counter:not(:empty){
    margin-left: 5px;
    font-weight: 600;
  }
}