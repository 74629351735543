$app__topbarItem_color: #666;
$app__topbarItem_color--hover: #444;
$app__topbarItem_color--active: #222;

$app__topbarItem_bgColor--hover: rgba(#000, 0);

$app__topbarItem_shadow--hover: inset 0 -2px rgba(#1194d8, 0.5) !default;
$app__topbarItem_shadow--active: inset 0 -2px rgba(#1194d8, 1) !default;

.app .app-topbar nav {
  align-self: stretch;
  height: $app__topbar_height;
  display: flex;
  align-items: center;

  a {
    line-height: $app__topbar_height;
    color: $app__topbarItem_color;

    //line-height: $appTopBarH;
    position: relative;
    display: block;
    padding: 0 12px;
    font-size: $app__topbar_fontSize;

    text-decoration: none;

    transition: all ease-in-out 0.1s;

    svg {
      font-size: 2rem;
      height: 1em;
      width: 1em;
      vertical-align: -.5rem;
      margin-right: 0.2rem;
    }

    &:hover {
      color: $app__topbarItem_color--hover;
      text-decoration: none;
      background-color: $app__topbarItem_bgColor--hover;
      box-shadow: $app__topbarItem_shadow--hover;
    }


    &.active {
      color: $app__topbarItem_color--active;
      box-shadow: $app__topbarItem_shadow--active;
      text-decoration: none;
    }


  }

}