.modal.app-workspace {

  background-color: #fff;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  z-index: 300;
  //transform: translateX(50px);

  &.active {
    pointer-events: auto;
    //transition: all 0.2s ease-in-out;

    opacity: 1;
    //transform: translateX(0);

  }

  .app-workspace-header {

    .metadata-row {
      background-color: rgba(248, 248, 248, 1);
      padding: 10px 20px;
      border-top: 1px solid rgba(234, 234, 234, 1);
      border-bottom: 1px solid rgba(234, 234, 234, 1);
      margin-left: -20px;
      margin-right: -20px;
    }

  }


  .app-workspace-header .title-row .icon-col .icon-wrapper {

    .back-icon {

      cursor: pointer;

      .icon {
        color: #aaa;
        width: 24px;
        height: 24px;
      }

      &:hover {
        .icon {
          color: #666;
        }
      }
    }

  }

}



.metadata-row {

  .spacer {
    width: 1px;
    height: 28px;
    background-color: #eaeaea;
    margin: -4px 10px;
  }

  .metaitem {
    line-height: 20px;

    .metaitem-label {
      color: rgba(0, 0, 0, .55);
      display: inline-block;
      margin-right: 5px;
    }

    .metaitem-value {
      font-weight: 500;
      color: #333;
    }

  }


  &:last-child {
    margin-bottom: -1px;
  }
}