
.databag {

  .indent-icon {
    position: relative;
    top: -2px;
    color: #9e9e9e;
    margin-right: 2px;
    font-size: 14px !important;
  }

  .details-header.indent-1, .details-row.indent-1 {
    padding-left: 16px;
  }

  .row.indent-1 {
    div:first-child .value:first-of-type {
    }
  }

  .details-header.indent-2, .details-row.indent-2 {
    padding-left: 32px;
  }

  .row.indent-2 {

    div:first-child .value:first-of-type {
      padding-left: 26px;
    }
  }

  .details-header.indent-3, .details-row.indent-3 {
    padding-left: 48px;
  }

  .row.indent-3 {
    div:first-child .value:first-of-type {
      padding-left: 46px;
    }
  }

  .details-header.indent-4, .details-row.indent-4 {
    padding-left: 64px;
  }

  .row.indent-4 {
    div:first-child .value:first-of-type {
      padding-left: 66px;
    }
  }
}
