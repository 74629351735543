$preapp__bgColor: #fdfdfd;

.app.preapp {
  height: 100vh;
  background-color: $preapp__bgColor;
  overflow: auto;

  .preapp-top-row {
    height: 100px;
    padding: 0 30px;
    font-size: 1.4rem;
  }

  .preauth-form-wrapper {
    max-width: 540px;
    border: 1px solid #eee;
    margin: 0 auto 30px auto;
    width: 95%;
    background-color: #fff;

    .main{
      position: static;
      padding: 20px 30px;
    }

    .content-wrapper {
      margin-top: 20px;
    }

    .footer {
      padding: 20px 30px;
      background-color: #f7f7f7;
      text-align: center;
      font-size: 13px;
    }

  }

  .preauth-texts {
    .title {
      font-size: 32px;
      font-weight: 200;
      margin-bottom: 0.2em;
    }

    .subtitle {
      font-size: 16px;
      font-weight: 300;
    }

    .content {
      font-size: 13px;
      line-height: 2.3rem;
      margin-top: 20px;
    }
  }

  .preapp-right-col {
    background-repeat: no-repeat;
    background-size: cover;

    &.preapp-right-col-darkbg {
      color: #fff;
    }

    .preapp-right-col-content {
      margin: 0 30px 30px 30px;
    }
  }

}


