.import-results-list {
  .import-result-item {
    padding: 14px 20px 10px 55px;
    border-bottom: 1px solid #eee;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 32px;
      border-left: 1px solid #444;
      width: 2px;
      top: 30px;
      bottom: 10px;
    }

    .status-icon {
      font-size: 24px;
      position: absolute;
      top: 10px;
      left: 20px;
    }

    .file {
      font-size: 13px;
      margin-bottom: 10px;
      display: inline-block;
    }

    .messages {
      font-size: 13px;
      margin-bottom: 10px;

    }

    .import-result-details {
      font-size: 13px;

      b {
        font-weight: 500;
        display: inline-block;
        margin-bottom: 6px;
      }

      i {
        font-style: normal;
        color: #999;
        display: inline-block;
        margin-right: 10px;
      }

      div {
        padding: 6px 0;
      }

      div + div {
        border-top: 1px solid #eee;
      }

    }

    &.alert {
      .status-icon{
        color: $alerts__errorColor;
      }

      &:before {
        border-left-color: rgba($alerts__errorColor, 0.5);
      }
    }

    &.info {
      .status-icon{
        color: $alerts__noticeColor;
      }

      &:before {
        border-left-color: rgba($alerts__noticeColor, 0.5);
      }
    }

    &.success {
      .status-icon{
        color: $alerts__successColor;
      }

      &:before {
        border-left-color: rgba($alerts__successColor, 0.5);
      }
    }


  }
}