.employee-dec-tab-label {

  font-weight: 400;

  &::after {
    display: block;
    content: "";
    clear: both;
  }

  .left-icon {
    width: 30px;
    float: left;
    .svg-inline--mdi {
      font-size: 18px;
    }

  }

  .text-label {
    float: left;
    width:calc(100% - 30px);

    .company-name {
      margin-bottom: 5px;
    }

    .dates {
      text-align: right;
      color: #666;
      font-size: 11px;
      b {
        font-weight: 500;
      }

      &.active-dates {
        color: #333;
      }

    }

  }



}