$databag__cell_padding: 6px 10px;

.databag {
  background-color: #fff;

  .databag.loading {
    opacity: 0.2;
  }

  .header-row {
    border-bottom: 1px solid #eee;

    .header {
      font-size: 1.5rem;
      font-weight: 600;
      padding: 10px 0px;

      &.small {
        font-size: 1.3rem;
      }
    }

    .buttons {
      padding-left: 10px;
      height: 100%;
      display: flex;
      align-items: stretch;
      justify-content: flex-end;

      > a[class=""], a.textbutton {

        color: #666;
        font-size: 1.3rem;
        /* line-height: 100%; */
        /* display: inline-block; */
        display: block;
        vertical-align: middle;
        align-self: stretch;
        height: 32px;
        line-height: 32px;
        padding: 0 8px;

        svg {
          font-size: 16px;
          vertical-align: -3px;
          margin-right: 1px;
          color: #1684bd;
        }

        &:hover {
          text-decoration: none;
          background-color: #cecece5c;
          color: #333;
        }
      }


    }
  }

  .data-row {
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;

    &.grayed-row {
      background-color: #f9f9f9;
    }

    &.no-bottom-border {
      border-bottom: 0;
    }

    /** wiersz z przyciskiem szczegółwy rozwijajacy szczegoly */
    &.details-header {
      border-bottom: 0;

      .details-header-name {
        display: inline-block;
        padding: 0px 10px 6px 10px;
        font-size: 12px;
        color: #666;
        cursor: pointer;

        .svg-inline--mdi {
          font-size: 1.8rem;
          vertical-align: -.25em;
          color: #1194d8;
          margin-left: -4px;
          transition: all 0.2s ease-in-out;
        }

        &:hover {
          color: #333;
        }

        &.opened {
          .svg-inline--mdi {
            transform: rotate(90deg);
          }
        }
      }

      .details-header-toolbar a {

        display: inline-block;
        padding: 0px 10px 6px 10px;
        font-size: 12px;
        color: #666;
        cursor: pointer;

        .svg-inline--mdi {
          font-size: 1.8rem;
          vertical-align: -.25em;
          color: #1194d8;
          margin-left: -4px;
          transition: all 0.2s ease-in-out;
        }

        &:hover {
          color: #333;
        }
      }
    }

    .col-details {
      margin-top: 6px;
      margin-left: 22px;
    }

    .col-visible-details {
      margin-top: 12px;
      margin-left: 5px;
      margin-right: 10px;
    }

    .subheader {
      font-size: 1.3rem;
      font-weight: 600;
      color: #333;
      padding: $databag__cell_padding;
      line-height: 1.9rem;
      background-color: rgba(0, 0, 0, 0.02);
    }

    .empty-table {
      font-size: 1.3rem;
      font-weight: 400;
      color: #999;
      font-style: italic;
      padding: 6px 10px;
      line-height: 1.9rem;
      text-align: center;
    }

    .name {
      font-size: 1.3rem;
      font-weight: 400;
      color: #777;
      padding: $databag__cell_padding;
      line-height: 1.9rem;

      b, strong {
        font-weight: 500;
      }
    }

    .value {
      font-size: 1.3rem;
      font-weight: 400;
      color: #333;
      padding: $databag__cell_padding;
      line-height: 1.9rem;

      b, strong {
        font-weight: 500;
      }
    }

    .subvalue {
      font-size: 1.3rem;
      font-weight: 400;
      color: #333;
      padding: $databag__cell_padding;
      line-height: 1.9rem;

      b {
        font-weight: 600;
      }
    }

    .svg-inline--mdi {
      font-size: 1.9rem;
    }

    &:hover {
      .edit a {
        opacity: 1 !important;
        visibility: visible !important;
      }
    }

    .edit {
      padding: $databag__cell_padding;

      a + a {
        padding-left: 10px;
        margin-left: 10px;
        border-left: 1px solid #ddd;
      }

      a[class=""], a:not([class]) {
        opacity: 0;
        transition: visibility 0.2s, all 0.2s ease-in-out;
        color: #999;
        display: inline-block;
        visibility: hidden;
        font-size: 1.3rem;
        line-height: 1.9rem;

        svg {
          font-size: 16px;
          vertical-align: -3px;
          margin-right: 5px;
        }

        svg.toolbar-button-nolabel {
          margin-right: 0;
        }

        &:hover {
          text-decoration: none;
          color: #333;
        }
      }
    }
  }


  .vert-item {
    margin-bottom: 10px;
    padding: 0 5px;

    .vert-label {
      font-size: 12px;
      color: #666;
      margin-bottom: 3px;
    }

    .vert-value {
      font-size: 1.3rem;
      color: #333;
      line-height: 1.9rem;

      .vert-value-empty-placeholder {
        color: #ddd;
        &:before {
          content: "\00a0\00a0\00a0\00a0\00a0\00a0";
          display: block;
          width: 30px;
          text-decoration: line-through;
        }
      }

    }
  }

  .vert-subhader {
    margin: 5px 10px 5px 5px;
    padding: 10px 0 5px 0;
    border-top: 1px solid #eee;
    font-size: 13px;
    font-weight: 500;
    color: #333;
  }
}

@import "databag_indents";