
.tabs-column {

  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;

  & > .row {
    flex-wrap: nowrap;
  }

  .tabs-left, .tabs-content {
    overflow: auto;
  }

  .tabs-left {
    background-color: rgba(251, 251, 251, 1);
    border-right: 1px solid rgba(0, 0, 0, 0.08);

    a {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: block;
      font-size: 1.3rem;
      padding: 10px;
      color: #333;

      &:hover {
        text-decoration: none;
        color: #333;
        background-color: rgba(240, 240, 240, 1);
      }

      &.active {
        background-color: #ecf6ff;
        font-weight: 600;
      }
    }
  }

  .tabs-content > .column{
    height: 100%;
    max-height: 100%;
  }

  .tabs-content {

    .tab-header-row {
      padding: 10px 0px;
      border-bottom: 1px solid #ddd;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #333;
        margin-left: 20px;
      }
    }

    .tab-content-row {
      overflow: auto;
      padding: 0px 20px;
    }

    .tabs-not-selected {
      text-align: center;
      align-self: flex-end;
      padding-top: 50px;
      font-size: 14px;
      font-weight: 500;
      color: #999;
    }

  }


}