
.change-save-indicator {
  position: absolute;
  vertical-align: -10px;
  display: inline-block;
  pointer-events: none;
  text-align: right;


  .load, .save {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.1s, opacity 0.1s ease-in-out;

    display: inline-block;
    position: absolute;
    right:0;
    height: 28px;
    font-size: 11px;
    padding: 9px 10px 6px 32px;
    line-height: 12px;
    background-color: #f4f4f4;
    margin-top: 1px;
  }

  .load {
    .loader {
      background-color: transparent;
      padding-top: 0;
      right: unset;
      left: 5px;

      .loader-svg {
        width: 24px;
        height: 28px;
      }
    }


    &.loading {
      opacity: 1;
      visibility: visible;
    }
  }

  .save {

    .savedicon {
      background-color: transparent;
      padding-top: 0;
      right: unset;
      left: 5px;
      top:0;
      bottom:0;
      position: absolute;

      svg {
        width: 20px;
        height: 28px;
        color: #1aa107;
      }
    }


    &.saved {
      opacity: 1;
      visibility: visible;
    }
  }

}

