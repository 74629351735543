
$rightWidth: 720px;

.modal.right {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 499;
  overflow: hidden;

  background-color: rgba(255,255,255,0.7);

  pointer-events: none;

  transition: all 0.3s ease-in-out;

  & > .modal-dialog {
    opacity:0;
    position: absolute;
    top:0;
    right: 0;
    transform: translateX(50px);
    bottom: 0;
    background-color: #fff;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    width: $rightWidth;
    display: flex;
    flex-direction: column;

    border-left: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);

    > .app-form {
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      min-height: 1px;
      position: relative;
    }

    .modal-dialog-header {
      border-bottom: 1px solid rgba(140, 140, 140, 0.2);
      padding: 15px 20px;
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      > div {
        justify-content: center;
      }

      .modal-dialog-title {
        flex-grow: 1;
        font-size: 16px;
        font-weight: 400;
        color: #333;

        .modal-dialog-super-title {
          color: #3989b3;
          font-weight: 500;
          font-size: 10px;
          margin-bottom: 2px;
        }
      }

      .modal-dialog-closeicon {
        color: #aaa;
        cursor: pointer;

        svg {
          height: 18px;
          width: 18px;
        }

        &:hover {
          color: #000;
        }
      }
    }

    .modal-dialog-submit-bottom, .modal-dialog-submit-top {
      padding: 10px 15px;
      background-color: #fbfbfb;
      clear: both;
      flex-grow: 0;
    }

    .modal-dialog-submit-top {
      border-bottom: 1px solid rgba(140, 140, 140, 0.3);
    }

    .modal-dialog-submit-bottom {
      border-top: 1px solid rgba(140, 140, 140, 0.3);
    }


    .modal-dialog-content {
      
      width: 100%;      
      overflow: auto;
      flex-grow: 1;
      
      &.dialog-list {
        padding: 0;

        .row {
          padding: 10px 15px;
          border-bottom: 1px solid #e8e8e8;

          &.selected {
            background-color: #4a87b6;
            color: #fff;
          }

        }

      }
    }

  }

  &.active {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(236, 236, 236, 0.63);

    pointer-events: auto;

    //transition: all 0.2s ease-in-out;

    > .modal-dialog {
      opacity: 1;
      transform: translateX(0);


      //transition: all 0.12s ease-in-out;

    }

  }


  &.wide {
    & > .modal-dialog {
      transform: translateX(50px);
      width: 90%;
      max-width: 1120px;
    }

    &.active {
      & > .modal-dialog {
        transform: translateX(0);
        width: 90%;
        max-width: 1120px;
      }
    }
  }

  &.full {
    & > .modal-dialog {
      transform: translateX(50px);
      width: 90%;
    }

    &.active {
      & > .modal-dialog {
        transform: translateX(0);
        width: 90%;
      }
    }
  }


}