button.button, input[type="submit"].button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  outline: none;
}

a.button {
  text-decoration: none;
}

.button {

  .svg-inline--mdi {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    width: 1em;
    overflow: visible;
    vertical-align: -.2em;
  }

}