/*!package buttons */

$buttons__fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$buttons__borderRadius: 0px !default;
//region Rozmiary

$buttons__regular: (
        'font': normal normal 400 1.3rem/2.8rem $buttons__fontFamily,
        'height': 3rem,
        'padding': 0 1.2rem,
        'iconSize': 1.8rem,
        'iconVerticalAlign': -.3em,
        'iconMargin': 0 0.3rem 0 -0.4rem
) !default;

$buttons__small: (
        'font': normal normal 400 1.1rem/2.3rem $buttons__fontFamily,
        'height': 2.4rem,
        'padding': 0 1rem,
        'iconSize': 1.6rem,
        'iconVerticalAlign': -.2em,
        'iconMargin': 0 0.5rem 0 -0.2rem,
) !default;

$buttons__big: (
        'font': normal normal 400 1.4rem/3.2rem $buttons__fontFamily,
        'height': 3.4rem,
        'padding': 0 1.2rem,
        'iconSize': 2.4rem,
        'iconVerticalAlign': -.3em,
        'iconMargin': 0 0.5rem 0 -0.5rem,
) !default;

$buttons__huge: (
        'font': normal normal 400 1.6rem/4.2rem $buttons__fontFamily,
        'height': 4.4rem,
        'padding': 0 2rem,
        'iconSize': 2.4rem,
        'iconVerticalAlign': -.25em,
        'iconMargin': 0 0.5rem 0 -1rem,
) !default;

//endregion

//region Style

$buttons__defaultColor: #f4f4f4 !default;
$buttons__defaultColorDark: #d2d2d2 !default;

$buttons__default: (
        'color': #333,
        'border': 1px solid $buttons__defaultColor,
        'bgColor': $buttons__defaultColor,
        'color--hover': #333,
        'border--hover': 1px solid $buttons__defaultColorDark,
        'bgcolor--hover': $buttons__defaultColorDark
) !default;

$buttons__defaultOutline: (
        'color': #333,
        'border': 1px solid $buttons__defaultColor,
        'color--hover': #333,
        'border--hover': 1px solid $buttons__defaultColorDark
) !default;

$buttons__primaryColor: #1194d8 !default;
$buttons__primaryColorDark: #0c6a9b !default;

$buttons__primary: (
        'color': #fff,
        'border': 1px solid $buttons__primaryColor,
        'bgColor': $buttons__primaryColor,
        'color--hover': #fff,
        'border--hover': 1px solid $buttons__primaryColorDark,
        'bgcolor--hover': $buttons__primaryColorDark
) !default;

$buttons__primaryOutline: (
        'color': $buttons__primaryColor,
        'border': 1px solid $buttons__primaryColor,
        'color--hover': $buttons__primaryColorDark,
        'border--hover': 1px solid $buttons__primaryColorDark
) !default;

$buttons__secondaryColor: #8599a5 !default;
$buttons__secondaryColorDark: #505d64 !default;

$buttons__secondary: (
        'color': #fff,
        'border': 1px solid $buttons__secondaryColor,
        'bgColor': $buttons__secondaryColor,
        'color--hover': #fff,
        'border--hover': 1px solid $buttons__secondaryColorDark,
        'bgcolor--hover': $buttons__secondaryColorDark
) !default;

$buttons__secondaryOutline: (
        'color': $buttons__secondaryColor,
        'border': 1px solid $buttons__secondaryColor,
        'color--hover': $buttons__secondaryColorDark,
        'border--hover': 1px solid $buttons__secondaryColorDark
) !default;

$buttons__warningColor: #c83b32 !default;
$buttons__warningColorDark: #832621 !default;

$buttons__warning: (
        'color': #fff,
        'border': 1px solid $buttons__warningColor,
        'bgColor': $buttons__warningColor,
        'color--hover': #fff,
        'border--hover': 1px solid $buttons__warningColorDark,
        'bgcolor--hover': $buttons__warningColorDark
) !default;

$buttons__warningOutline: (
        'color': $buttons__warningColor,
        'border': 1px solid $buttons__warningColor,
        'color--hover': $buttons__warningColorDark,
        'border--hover': 1px solid $buttons__warningColorDark
) !default;

$buttons__acceptColor: #1db108 !default;
$buttons__acceptColorDark: #168606 !default;

$buttons__accept: (
        'color': #fff,
        'border': 1px solid $buttons__acceptColor,
        'bgColor': $buttons__acceptColor,
        'color--hover': #fff,
        'border--hover': 1px solid $buttons__acceptColorDark,
        'bgcolor--hover': $buttons__acceptColorDark
) !default;

$buttons__acceptOutline: (
        'color': $buttons__acceptColor,
        'border': 1px solid $buttons__acceptColor,
        'color--hover': $buttons__acceptColorDark,
        'border--hover': 1px solid $buttons__acceptColorDark
) !default;



$buttons__translucent: (
        'color': #fff,
        'border': 1px solid rgba(255, 255, 255, 0.4),
        'bgColor': rgba(255, 255, 255, 0.1),
        'color--hover': #fff,
        'border--hover': 1px solid 1px solid rgba(255, 255, 255, 1),
        'bgcolor--hover': rgba(255, 255, 255, 0.05)
) !default;

$buttons__translucentOutline: (
        'color': #fff,
        'border': 1px solid rgba(255, 255, 255, 0.4),
        'color--hover': #fff,
        'border--hover': 1px solid rgba(255, 255, 255, 1)
) !default;

//endregion

@import "svg";
@import "reset";
@import "mixins";

.button, button.button, input[type="submit"].button {
  transition: all 0.1s ease-in-out;
  display: inline-block;
  border-radius: $buttons__borderRadius;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    &.dropdown:after {
      opacity: 1;
    }
  }



  //sizes

  @include buttons__size($buttons__regular);

  &.small {
    @include buttons__size($buttons__small);

  }

  &.big {
    @include buttons__size($buttons__big);
  }

  &.huge {
    @include buttons__size($buttons__huge);
  }

  @include buttons__style($buttons__default, $buttons__defaultOutline, #333);

  //types

  &.primary {
    @include buttons__style($buttons__primary, $buttons__primaryOutline, #fff);
  }

  &.secondary {
    @include buttons__style($buttons__secondary, $buttons__secondaryOutline, #fff);
  }

  &.warning {
    @include buttons__style($buttons__warning, $buttons__warningOutline, #fff);
  }

  &.accept {
    @include buttons__style($buttons__accept, $buttons__acceptOutline, #fff);
  }

  &.translucent {
    @include buttons__style($buttons__translucent, $buttons__translucentOutline, #fff);
  }

}
