$blank: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23c8c8c8' d='M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' /%3E%3C/svg%3E%0A");
$hover: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23c8c8c8' d='M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4M11,16.5L6.5,12L7.91,10.59L11,13.67L16.59,8.09L18,9.5L11,16.5Z' /%3E%3C/svg%3E");
$checked: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%231194d8' d='M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' /%3E%3C/svg%3E");

.grid label.checkbox {

  display: block;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 20px;

  input {
    display: none;
  }

  span {
    vertical-align: -3px;
    display: none;
    width: 20px;
    height: 20px;
    background-size: contain;
    background-image: $blank;
  }

  &:hover span{
    background-image: $hover;
  }

  input:checked + span {
    display: inline-block !important;
    background-image: $checked;
  }

}

.item.grid-card .checkbox{
  position: absolute;
  right: 10px;
  top: 10px;

  input:checked + span {
    background-color: #fff;
    border-radius: 50%;
  }
}

.item.grid-card:hover span {
  background-color: #fff;
  border-radius: 50%;
}

tr:hover label.checkbox span, .item:hover label.checkbox span, .hover label.checkbox span {
  display: inline-block;
}