
$grid__qs__borderColor: #dedede !default;
$forms__borderColor--disabled: #efefef !default;
$forms__borderColor--hover: #aaa !default;
$forms__borderColor--focus: #3f83b4 !default;

.grid .grid-toolbar .quick-search-wrapper {

  margin-right: 10px;

  .search-icon {
    width: 30px;
    height: 20px;
    display: inline-block;
    coloR: #999;
    position: relative;
    vertical-align: -5px;
  }

  .clear-quick-search {
    padding: 5px;
    display: none;
    svg {
      height: 18px;
      width: 18px;
      vertical-align: -5px;
      color: #999;
    }

    &:hover {
      svg {
        color: #0c6a9b;
      }
    }
  }

  .quick-search.active + .clear-quick-search {
    display: inline-block;
  }

  .quick-search {
    outline: none;
    border: 1px solid $grid__qs__borderColor;
    font-size: 1.3rem;
    line-height: 2.8rem;
    padding: 0 0.5rem 0 30px;
    width: 200px;
    min-height: 2.8rem;
    margin-left: -30px;

    &:hover {
      border: 1px solid $forms__borderColor--hover;
    }

    &:focus {
      border: 1px solid $forms__borderColor--focus;
    }

    &.active {
      background-color: #fffdda;
    }

  }

}