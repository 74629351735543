/*!package dropdown */

$dropdown__fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$dropdown__border: 1px solid #d1d1d1 !default;
$dropdown__borderRadius: 0px !default;
$dropdown__bgColor: #fff !default;
$dropdown__shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2) !default;
$dropdown__fontSize: 1.2rem !default;

$dropdown__listItem_padding: 8px 10px !default;
$dropdown__listItem_borderBottom: 0px !default;

$dropdown__listItem_color: #333 !default;
$dropdown__listItem_bgColor: #fff !default;

$dropdown__listItem_color--selected: #333 !default;
$dropdown__listItem_bgColor--selected: #f3f3f3 !default;

.dropdown-container {
  position: absolute;
  z-index: 1223242;
  display: block;

  font-family: $dropdown__fontFamily;
  font-size: $dropdown__fontSize;
  border: $dropdown__border;
  box-shadow: $dropdown__shadow;
  background-color: $dropdown__bgColor;

  overflow: visible;
  padding: 0;
  margin: 0;
  top: 1px;

  max-height: 300px;
  min-width: 100px;

  &.with-left-arrow, &.with-right-arrow {
    transform: translate3d(0, 10px, 0);
    &:before {
      content: '';

      border-left: $dropdown__border;
      border-top: $dropdown__border;
      background-color: $dropdown__bgColor;

      display: block;
      overflow-y: visible;

      width: 11px;
      height: 11px;
      transform: rotate(45deg);
      margin-bottom: -11px;
      z-index: -1;
      position: relative;
    }
  }

  &.with-left-arrow {
    &:before {
      left: 11px;
      top: -7px;
    }
  }

  &.with-right-arrow {
    &:before {
      float:right;
      left: -11px;
      top: -7px;
    }
  }

}


.dropdown-filter-input {

  position: absolute;
  z-index: 1223242;
  display: block;

  font-size: 12px;
  font-family: Arial;

  border: solid 1px #DDD;
  border: solid 1px rgba(0, 0, 0, .2);

  overflow: visible;
  padding: 0;
  margin: 0;
  top: 1px;

  height: 28px;
  overflow-y: auto;

  min-width: 100px;
  //height: 100px;

  background-color: #fff;

  input {
    border: 0;
    width: 100%;
    padding: 5px;
    font-size: 12px;
    font-family: Arial;
  }

}

.dropdown-list {

  &.dropdown-filter {

  }

  > *, > a {
    white-space: nowrap;
    padding: $dropdown__listItem_padding;
    border-bottom: $dropdown__listItem_borderBottom;

    display: block;
    text-decoration: none;

    color: $dropdown__listItem_color;
    background-color: $dropdown__listItem_bgColor;

    &:last-child {
      border-bottom: 0px;
    }

    &.selected {
      text-decoration: none;
      background-color: $dropdown__listItem_bgColor--selected;
      color: $dropdown__listItem_color--selected;
    }

    .svg-inline--mdi {
      font-size: 18px;
      margin-right: 5px;
      vertical-align: -4px;
    }
  }

}

